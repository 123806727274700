import React, { createContext, useReducer } from 'react';
import { playerReducer } from '../reducers/playerReducer';


const initialState = {};

export const PlayerContext = createContext({});

export const PlayerContextProvider = ({ children }) => {
    const [playerState, playerDispatch] = useReducer(playerReducer, initialState);

    return <PlayerContext.Provider value={{ playerState, playerDispatch }}>{children}</PlayerContext.Provider>;
};

