export const mediaApiHost = 'http://localhost:3003/media';
export const mediaImagesHost = 'http://localhost:3003/images';

export const stateName = {
    DEVICE_STATE: 'device',
    CLIENT_STATE: 'client',
    TRIGGER_STATE: 'trigger',
    LIBRARY_STATE: 'library',
    TORRENT_STATE: 'torrent',
    PLAYER_STATE: 'player',
    AUTOMATION_STATE: 'automation',
};

export const controller = {
    RELAY: 'relay',
    SENSOR: 'sensor',
    UART: 'uart',
    DMX: 'dmx',
    PLAYER: 'player',
    TORRENT: 'torrent',
    AUTOMATION: 'automation,',
    TASMOTA: 'tasmota',
    ZIGBEE: 'zigbee',
    YEELIGHT: 'yeelight',
};

export const channel = {
    ERROR: 'error',
    ADMIN: 'admin',
    WSS: 'wss',
    HTTP: 'http',
    CONTROLLER: 'controller',
    AUTOMATION: 'automation',
    TORRENT: 'torrent',
    PLAYER: 'player',
};

export const controllerState = {
    [controller.RELAY]: stateName.DEVICE_STATE,
    [controller.SENSOR]: stateName.DEVICE_STATE,
    [controller.UART]: stateName.DEVICE_STATE,
    [controller.DMX]: stateName.DEVICE_STATE,
    [controller.TASMOTA]: stateName.DEVICE_STATE,
    [controller.PLAYER]: stateName.PLAYER_STATE,
    [controller.TORRENT]: stateName.TORRENT_STATE,
    [controller.AUTOMATION]: stateName.AUTOMATION_STATE,
    [controller.ZIGBEE]: stateName.DEVICE_STATE,
    [controller.YEELIGHT]: stateName.DEVICE_STATE,
};

export const controllerChannel = {
    [controller.RELAY]: channel.CONTROLLER,
    [controller.SENSOR]: channel.CONTROLLER,
    [controller.UART]: channel.CONTROLLER,
    [controller.DMX]: channel.CONTROLLER,
    [controller.PLAYER]: channel.PLAYER,
    [controller.TORRENT]: channel.TORRENT,
    [controller.AUTOMATION]: channel.AUTOMATION,
};

export const controllerWithAddress = [
    controller.RELAY,
    controller.SENSOR,
    controller.UART,
    controller.DMX,
];

// flatStateControllers does not nest data in 'controller' property
export const flatStateController = [controller.PLAYER, controller.TORRENT, controller.AUTOMATION];
