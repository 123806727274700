import React, { useEffect, useCallback, useState } from 'react';

const eventList = ['pageshow', 'focus', 'blur', 'visibilitychange', 'resume'];

const getState = () => {
    if (document.visibilityState === 'hidden') {
        return 'hidden';
    }
    if (document.hasFocus()) {
        return 'active';
    }
    return 'passive';
};

export const useForegroundState = () => {
    const [state, setState] = useState(getState());

    const onDisplayStateChange = useCallback(() => {
        setState(getState());
    }, [state, setState]);

    useEffect(() => {
        eventList.forEach(type => {
            window.addEventListener(type, onDisplayStateChange, { capture: true });
        });

        return () => {
            eventList.forEach(type => {
                window.removeEventListener(type, onDisplayStateChange);
            });
        };
    }, []);

    return state;
};
