import React, { useRef, useState } from 'react';
import { ButtonComponent } from '../Button/Button';

import style from './LedButton.scss';
import { LedModal } from './LedModal';

const LedButton = props => {
    const timeoutRef = useRef(null);
    const [openSettings, setOpenSettings] = useState(false);

    return (
        <div
            className={style.wrapper}
            onTouchStart={() => {
                timeoutRef.current = setTimeout(() => {
                    setOpenSettings(true);
                }, 500);
            }}
            onTouchEnd={() => {
                clearTimeout(timeoutRef.current);
            }}
        >
            <LedModal isOpen={openSettings} onClose={() => setOpenSettings(false)} {...props} />
            <ButtonComponent {...props} />
        </div>
    );
};

export default LedButton;
