import React from 'react';
import get from 'lodash.get';
import { ButtonComponent } from '../Button/Button';
import style from './BlindButton.scss';

const BlindButton = props => {
    const state = get(props, `state.${props.uid}.blind.${props.address}`);
    return (
        <div>
            <ButtonComponent
                {...props}
                icon={props.isCurtain ? 'linea/arrows_expand_horizontal1' : 'mdi/blinds-open'}
                action="openToggle"
            />
            <div className={style.state}>{(state || 'N/A').toUpperCase()}</div>
            <ButtonComponent
                {...props}
                icon={props.isCurtain ? 'linea/arrows_shrink_horizontal1' : 'mdi/blinds'}
                action="closeToggle"
            />
        </div>
    );
};

export default BlindButton;
