import React, { useState } from 'react';
import get from 'lodash.get';
import { Icon } from '../Icon/Icon';
import { DebugProperty } from './DebugProperty';
import { DebugClosedProperty } from './DebugClosedProperty';

import styles from './DebugEntry.scss';
import { DebugOneLineProperty } from './DebugOneLineProperty';

export const DebugEntry = ({ uid, name, property, state, dataPath }) => {
    const [isVisible, setIsVisible] = useState(false);

    if (typeof property === 'object' && ['{}', '[]'].includes(JSON.stringify(property))) {
        property = Array.isArray(property) ? '[ ]' : '{ }';
    }

    const toggle = () => setIsVisible(!isVisible);
    const selector = `${dataPath}.${name}`.split('.').slice(2);
    const selectedVal = get(state, selector);
    const isObject = property instanceof Object;
    const isArray = Array.isArray(property);
    const canBeOpened = isObject || isArray;


    const closedEl = (
        <DebugClosedProperty
            className={styles.closedPropertyWrapper}
            isObject={isObject}
            isArray={isArray}
            onClick={toggle}
        />
    );

    const openEl = (
        <DebugProperty
            className={styles.openPropertyWrapper}
            uid={uid}
            obj={property}
            state={state}
            dataPath={`${dataPath}.${name}`}
        />
    );

    const propertyEl = canBeOpened ? (
        isVisible ? openEl : closedEl
    ) : (
        <DebugOneLineProperty
            className={styles.oneLinePropertyWrapper}
            uid={uid}
            name={name}
            val={property}
            state={state}
            dataPath={`${dataPath}.${name}`}
        />
    );

    const iconEl = (
        <Icon
            className={styles.iconOpenClose}
            width="14"
            name={isVisible ? 'mdi/chevron-down': 'mdi/chevron-right'}
        />
    );


    return (
        <li className={styles.wrapperLi}>
            <span className={styles.keyWrapper}>
                {canBeOpened && iconEl}
                <span className={styles.keyEl} onClick={toggle}>{name}:</span>
            </span>

            {propertyEl}
        </li>
    );
};

