import React, { useContext } from 'react';
import get from 'lodash.get';

import { Chart } from '../../../Chart/Chart';
import { DeviceContext } from '../../../../context/deviceContext';
import { SensorLine } from './SensorLine';

import styles from './sensors.scss';

// /chart/esp30aea4030f4c/15

export const Sensors = ({ sensors = [] }) => {
    const deviceState = useContext(DeviceContext);

    return (
        <div className={styles.wrapper}>
            <div className={styles.chart}>
                <Chart />
            </div>

            <div className={styles.grid}>
                {sensors.map(sensorConfig => {
                    const { zoneName, data } = sensorConfig;
                    const sensor = get(deviceState, data);

                    if (!sensor) {
                        return null;
                    }

                    const matches = data.match(/^deviceState\['(.+)'\].(sensor|energy).(\d+)/);

                    if (!matches) {
                        return null;
                    }

                    const [, uid, address] = matches;

                    return (
                        <SensorLine
                            key={zoneName}
                            href={`/chart/${uid}/${address}?title=${encodeURIComponent(zoneName)}`}
                            zoneName={zoneName}
                            sensorData={sensor}
                            // temperature={sensor.temperature}
                            // humidity={sensor.humidity}
                        />
                    );
                })}
            </div>
        </div>
    );
};
