import { SyntheticEvent, useCallback, useMemo } from 'react';

type PropsType = {
    onHold: (arg0: SyntheticEvent) => void;
    onClick: (arg0: SyntheticEvent) => void;
    delay?: number;
};

let started = false;
let timer: number | null;

export const useLongPress = (props: PropsType) => {
    const { onClick = () => {}, onHold = () => {}, delay = 300 } = props;

    // const callback = useCallback(
    //     ev => {
    //         onHold(ev);
    //     },
    //     [onHold]
    // );

    const start = useCallback(
        (ev: SyntheticEvent<HTMLElement>) => {
            started = true;
            timer = setTimeout(() => {
                onHold(ev);
                timer = null;
            }, delay);
        },
        [delay]
    );

    const move = useCallback(
        (ev: SyntheticEvent<HTMLElement>) => {
            started = false;
            if (timer) {
                clearTimeout(timer);
            }
        },
        [delay, started, timer]
    );

    const stop = useCallback(
        (ev: SyntheticEvent<HTMLElement>) => {
            if (!started) {
                return;
            }
            if (timer) {
                clearTimeout(timer);
                onClick(ev);
            }
            started = false;
        },
        [onClick, started, timer]
    );

    return useMemo(() => {
        return 'ontouchstart' in document.documentElement
            ? {
                  onTouchStart: start,
                  onTouchMove: move,
                  onTouchEnd: stop,
              }
            : {
                  onMouseDown: start,
                  onMouseMove: move,
                  onMouseUp: stop,
                  onMouseLeave: stop,
              };
    }, [start, stop]);
};
