import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Icon } from '../Icon/Icon';

import { getValueFromState, getChannelFromState } from '../../helpers/stateHelper';
import { useDebouncedCallback } from 'use-debounce';
import { emitAction } from '../../actions/actions';

import style from './LedModal.scss';


export const LedModal = props => {
    const { onClose, isOpen, state, uid, controller, address } = props;
    const initialLoadRef = useRef(true);
    const channel = getChannelFromState({ state, uid, controller });
    const brightnessState = getValueFromState({
        state,
        uid,
        controller,
        address,
        selector: 'brightness',
    });

    const portalElement = useRef(document.createElement('div'));
    const [brightness, setBrightness] = useState(0);

    useEffect(() => {
        if (isOpen) {
            document.getElementsByTagName('body')[0].appendChild(portalElement.current);
        } else if (!initialLoadRef.current) {
            document.getElementsByTagName('body')[0].removeChild(portalElement.current);
        }
    }, [isOpen]);

    useEffect(() => {
        setBrightness(brightnessState);
    }, [brightnessState]);

    const [debouncedSendBrightness] = useDebouncedCallback(brightnessValue => {
        emitAction({
            channel,
            uid,
            controller,
            address,
            action: 'setBrightness',
            data: { values: [brightnessValue] },
        });
    }, 500);

    const values =
        getValueFromState({
            state,
            uid,
            controller,
            address,
            selector: 'values',
        }) || {};

    const [cctValue, setCttValue] = useState(values[0] - values[1] + 255);
    const [cctValues, setCttValues] = useState([255, 255]);

    const [debouncedSendValues] = useDebouncedCallback(values => {
        emitAction({
            channel,
            uid,
            controller,
            address,
            action: 'setGroupValues',
            data: { values },
        });
    }, 500);

    useEffect(() => {
        let newValues = [255, 255];
        if (cctValue < 255) {
            newValues = [Number(cctValue), 255];
        } else if (cctValue > 255) {
            newValues = [255, (cctValue - 510) * -1];
        }
        setCttValues(newValues);

        if (!initialLoadRef.current) {
            debouncedSendValues(newValues);
        }
    }, [cctValue, debouncedSendValues]);

    useEffect(() => {
        initialLoadRef.current = false;
    }, []);

    if (!isOpen) {
        return null;
    }

    return ReactDOM.createPortal(
        <div className={style.overlay}>
            <div className={style.wrapper}>
                <div className={style.closeButton} onClick={onClose}>
                    <Icon name="icon54/close" />
                </div>
                <div style={{ marginBottom: '20px' }}>
                    Brightness ({brightness}%)
                    <input
                        type="range"
                        min="0"
                        max="100"
                        value={brightness}
                        onChange={evt => {
                            const brightnessValue = evt.currentTarget.value;
                            setBrightness(brightnessValue);
                            debouncedSendBrightness(brightnessValue);
                        }}
                        className={style.slider}
                        orient="vertical"
                    />
                </div>

                {values.length === 2 && (
                    <div>
                        {`${cctValues[0]} Cold White < - > Warm White ${cctValues[1]}`}
                        <input
                            type="range"
                            min="0"
                            max="510"
                            value={cctValue}
                            onChange={evt => setCttValue(evt.currentTarget.value)}
                            className={`${style.slider} ${style.cct}`}
                            orient="vertical"
                        />
                    </div>
                )}
            </div>
        </div>,
        portalElement.current
    );
};
