import React, { useState } from 'react';
import get from 'lodash.get';
import { Icon } from '../Icon/Icon';
import { DebugObject } from './DebugObject';
import { DebugEntry } from './DebugEntry';
import { DebugOneLineProperty } from './DebugOneLineProperty';

export const DebugProperty = ({ className, uid, obj, state, dataPath }) => {
    switch (true) {
        case obj instanceof Object:
            const entries = Object.entries(obj).sort(([a, b]) => {
                if (a === null || b === null) {
                    return 0;
                }
                return a[0] > b[0] ? 1 : b[0] > a[0] ? -1 : 0;
            });
            if (!entries.length) {
                return <DebugOneLineProperty val="{ }" />;
            }
            return entries.map(([k, v]) => (
                <ul className={className} key={k}>
                    <DebugEntry uid={uid} name={k} property={v} state={state} dataPath={dataPath} />
                </ul>
            ));
        default:
            return <span className={className}>default</span>;
    }
};
