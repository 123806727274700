import { useCallback, useContext, useEffect } from 'react';
import ReconnectingWebSocket from 'ReconnectingWebSocket';

import { ClientContext } from '../../context/clientContext';
import { DeviceContext } from '../../context/deviceContext';
import { TriggerContext } from '../../context/triggerContext';
import { AutomationContext } from '../../context/automationContext';
import { LibraryContext } from '../../context/libraryContext';
import { PlayerContext } from '../../context/playerContext';
import { TorrentContext } from '../../context/torrentContext';
import { UiContext } from '../../context/uiContext';

import { SET_CLIENT_STATE } from '../../reducers/clientReducer';
import { onMessage } from './onMessage';

let ws;
const wsBuffer = [];
const startTime = Date.now();

class EventDispatcher {
    constructor() {
        this._listeners = [];
    }

    hasEventListener(type, listener) {
        return this._listeners.some(item => item.type === type && item.listener === listener);
    }

    addEventListener(type, listener) {
        if (!this.hasEventListener(type, listener)) {
            this._listeners.push({ type, listener, options: { once: false } });
        }
        // console.log(`${this}-listeners:`,this._listeners);
        return this;
    }

    removeEventListener(type, listener) {
        let index = this._listeners.findIndex(
            item => item.type === type && item.listener === listener
        );
        if (index >= 0) this._listeners.splice(index, 1);
        //        console.log(`${this}-listeners:`, this._listeners);
        return this;
    }

    removeEventListeners() {
        this._listeners = [];
        return this;
    }

    dispatchEvent(evt) {
        this._listeners
            .filter(item => item.type === evt.type)
            .forEach(item => {
                const {
                    type,
                    listener,
                    options: { once },
                } = item;
                listener.call(this, evt);
                if (once === true) this.removeEventListener(type, listener);
            });
        // console.log(`${this}-listeners:`,this._listeners);
        return this;
    }
}

// class DataStream extends EventTarget {
class DataStream extends EventDispatcher {
    stream({ eventName, payload }) {
        // payload must be passed to { detail } in second argument
        this.dispatchEvent(new CustomEvent(eventName, { detail: payload }));
    }
}
export const dataStream = new DataStream();
dataStream._addEventListener = dataStream.addEventListener;

dataStream.addEventListener = (eventName, fn) => {
    console.debug(`adding listener for ${eventName}`);
    send({ topic: 'subscribeDataStreamEvent', payload: { eventName } });
    dataStream._addEventListener(eventName, fn);

    return () => {
        console.debug(`removing listener for ${eventName}`);
        dataStream.removeEventListener(eventName, fn);
        send({ topic: 'unsubscribeDataStreamEvent', payload: { eventName } });
    };
};

// dataStream.addEventListener('debug', ({ type, detail }) => {
//     console.log('dataStream fired debug', type, detail);
// });

export const send = ({ topic, payload }) => {
    if (typeof topic !== 'string') {
        return console.error('Topic must be string.', topic);
    }
    if (!(payload instanceof Object)) {
        return console.error('Payload must be object.', payload);
    }

    if (ws && ws.readyState === 1) {
        ws.send(JSON.stringify({ topic, payload }));
    } else {
        const timeSinceStart = Date.now() - startTime;
        if (timeSinceStart > 2000) {
            console.warn('Websocket not connected', timeSinceStart);
        }

        // keep for sending later. no duplicates
        const isDuplicate = wsBuffer.some(
            item => JSON.stringify(item) === JSON.stringify({ topic, payload })
        );
        if (!isDuplicate) {
            wsBuffer.push({ topic, payload });
        }
    }
};

const flushBuffer = () => {
    while (wsBuffer.length) {
        send(wsBuffer.shift());
    }
};

export const login = ({ username, password }) => {
    send({ topic: 'login', payload: { username, password } });
};

export const useWebsocket = () => {
    const { automationDispatch } = useContext(AutomationContext);
    const { clientState, clientDispatch } = useContext(ClientContext);
    const { deviceDispatch } = useContext(DeviceContext);
    const { triggerDispatch } = useContext(TriggerContext);
    const { libraryDispatch } = useContext(LibraryContext);
    const { playerDispatch } = useContext(PlayerContext);
    const { torrentDispatch } = useContext(TorrentContext);
    const { uiDispatch } = useContext(UiContext);
    const {
        accessToken,
        wsHost,
        isConnected,
        isConnecting,
        isAuthorized,
        username,
        password,
    } = clientState;

    const dispatchers = {
        automationDispatch,
        clientDispatch,
        deviceDispatch,
        triggerDispatch,
        libraryDispatch,
        playerDispatch,
        torrentDispatch,
        uiDispatch,
    };

    const setClientState = useCallback(
        (obj = {}) => {
            clientDispatch({
                type: SET_CLIENT_STATE,
                payload: {
                    wsReadyState: ws.readyState,
                    ...obj,
                },
            });
        },
        [clientDispatch]
    );

    const init = useCallback(() => {
        if (ws && ws.close) {
            ws.close();
        }

        console.log('init Websocker connection:', { wsHost, username, password });
        ws = new ReconnectingWebSocket(wsHost, null, {
            debug: true,
            reconnectInterval: 200,
            reconnectDecay: 1.25,
            maxReconnectInterval: 3000,
            automaticOpen: false,
        });
        window.ws = ws;
        setClientState({
            isConnected: false,
            isConnecting: true,
            wsHost,
        });

        ws.onopen = () => {
            // console.log('Connected to WSSSSSSSSSSSSSSSSSSSSSSSSS');
            setClientState({
                isConnected: true,
                isConnecting: false,
            });

            if (accessToken) {
                console.time('authenticate');
                console.time('authenticate2');
                send({ topic: 'authenticate', payload: { accessToken } });
            } else if (username && password) {
                login({ username, password });
                setClientState({ password: null });
            }
        };

        ws.onmessage = onMessage.bind(null, dispatchers, dataStream);
        ws.onclose = ev => {
            console.log('ws onClose', ev);
            setClientState({ isConnected: false, isConnecting: true });
        };
        ws.onerror = error => {
            console.error('ws onError', error);
            setClientState({ error });
        };

        ws.open();
    }, [accessToken, wsHost, username, password, dispatchers, setClientState]);

    useEffect(() => {
        // why this one is firing on state change?

        const readyToLogin = !!wsHost && (!!accessToken || (!!username && !!password));
        // console.log({
        //     readyToLogin,
        //     wsHost,
        //     accessToken,
        //     username,
        //     password,
        //     isConnecting,
        //     isConnected,
        // });

        if (readyToLogin && !isConnecting && !isConnected) {
            init();
        }
    }, [init, accessToken, wsHost, username, password, isConnecting, isConnected]);

    useEffect(() => {
        if (isAuthorized) {
            flushBuffer();
        }
    }, [isAuthorized]);
};
