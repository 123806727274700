import React from 'react';
import { SensorDiff } from './SensorDiff';
import { mapRange } from '../../../../helpers/utils';

import styles from './SensorValue.scss';

const UPDATE_TIMEOUT = 3 * 60 * 60;

export const SensorValue = ({ data = {}, fractionDigits, units, className }) => {
    const { value: rawValue, prevValue, changeTime /*prevChangeTime*/ } = data;
    const secondsSinceChange = (Date.now() - changeTime) / 1000;
    const validValue = Number.isFinite(rawValue) && secondsSinceChange < UPDATE_TIMEOUT;
    const fade = mapRange(secondsSinceChange, 50, UPDATE_TIMEOUT, 0, 0.4);
    const opacity = isNaN(fade) ? 0.5 : 1 - fade;

    if (typeof fractionDigits === 'undefined') {
        fractionDigits = rawValue < 10 ? 2 : 1;
    }

    const value = validValue ? rawValue.toFixed(fractionDigits) : String.fromCharCode(8212);

    return (
        <span className={className} style={{ opacity }}>
            <span className={styles.value}>{value}</span>
            <span className={styles.detailsWrapper}>
                <span>{units}</span>
                {validValue && <SensorDiff diff={rawValue - prevValue} />}
            </span>
        </span>
    );
};
