import cloneDeep from 'lodash.clonedeep';
import merge from 'lodash.merge';

export const SET_AUTOMATION_STATE = 'SET_AUTOMATION_STATE';

export const automationReducer = (state, action) => {
    const { type, payload } = action;
    // console.log('automationReducer', state, action);

    switch (type) {
        case SET_AUTOMATION_STATE:
            return merge(
                cloneDeep(state),
                payload
            );

        default:
            return state;
    }
};
