import React, { createContext, useReducer, useContext } from 'react';
import { triggerReducer } from '../reducers/triggerReducer';

const initialState = {};

export const TriggerContext = createContext({});

export const TriggerContextProvider = ({ children }) => {
    const [triggerState, triggerDispatch] = useReducer(triggerReducer, initialState);
    return (
        <TriggerContext.Provider value={{ triggerState, triggerDispatch }}>
            {children}
        </TriggerContext.Provider>
    );
};

export const useTrigger = () => useContext(TriggerContext);
