import React from 'react';

export const DebugClosedProperty = ({ className, isArray, isObject, onClick = () => {} }) => {
    let text;
    switch (true) {
        case isArray:
            text = '[...]';
            break;
        case isObject:
            text = '{...}';
            break;
        default:
            text = '(...)';
    }

    return (
        <span
            className={className}
            onClick={onClick}
        >
            <span>{text}</span>
        </span>
    );
};
