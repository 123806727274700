type AppInfoType = {
    appVersion: string;
};

let appInfoCache: AppInfoType;

export const getAppInfo = async (forceRefresh?: boolean): Promise<AppInfoType> => {
    if (appInfoCache && !forceRefresh) {
        return appInfoCache;
    }
    const response = await fetch(`/appInfo.json?${Date.now()}`);
    return (await response.json()) as AppInfoType;
};

export const hasNewAppVersion = async (forceRefresh?: boolean) => {
    const { appVersion } = await getAppInfo(forceRefresh);
    return appVersion !== process.env.appVersion;
};
