import { isValidElement, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import styles from './SwiperSlide.scss';
import Heading from '../Heading/Heading';
// import { useIntersect } from 'src/hooks/useIntersect';
import { SwiperContext } from './Swiper';

export type SwiperSlidePropsType = {
    _key: string;
    children: ReactNode;
    title?: string | ReactNode;
    className?: string;
    activeClassName?: string;
};

// const buildThresholdArray = () => Array.from(Array(10).keys(), i => i / 10);

// const translateRotate = (val: number) => {
//     // return val < 0.8 ? 0.8 : val > 0.9 ? 1 : val;
//     return -val * 100;
// };

export const SwiperSlide = (props: SwiperSlidePropsType) => {
    const { _key, children, title, className, activeClassName } = props;

    if (!_key) {
        throw Error(`_key not defined!`);
    }

    const [isActive, setIsActive] = useState(false);
    const ref = useRef(null);
    const isRegistered = useRef(false);
    const slideWrapperClassNames = classnames(styles.wrapper, className, {
        [styles.withTitle]: !!title,
        [styles.active]: isActive,
        [activeClassName || 'activeSlide']: activeClassName && isActive,
    });

    const context = useContext(SwiperContext);

    useEffect(() => {
        const node = ref?.current;

        // if (node !== null && context.observer?.current?.observe) {
        if (node !== null && context.registerSlide && !isRegistered.current) {
            // context.observer?.current?.observe(node);

            isRegistered.current = context.registerSlide({
                _key,
                node,
                props,
                setIsActive,
            });
        }

        // TODO: unobserve() on unmount
    }, [ref, _key, isRegistered, context]);

    const style = {
        // opacity: transformRatio,
        // transform: `rotateY(${translateRotate(transformRatio)}deg)`,
    };

    if (title) {
        return (
            <div className={slideWrapperClassNames} ref={ref} style={style}>
                {typeof title === 'string' && (
                    <Heading className={styles.heading} size={3} text={title} />
                )}
                {isValidElement(title) && title}
                <div className={styles.childrenWithTitle}>{children}</div>
            </div>
        );
    }

    return (
        <div className={slideWrapperClassNames} ref={ref} style={style} data-key={_key}>
            {children}
        </div>
    );
};
