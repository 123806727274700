import React, { useState, useContext } from 'react';
import { Icon } from '../Icon/Icon';

import { ClientContext } from '../../context/clientContext';
import { SET_CLIENT_STATE } from '../../reducers/clientReducer';

import styles from './Login.scss';

const validateHostUrl = url => {
    const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws|wss';
    return new RegExp(`^(${protocol})://(.+){3}`).test(url);
};

export const Login = () => {
    const { clientState, clientDispatch } = useContext(ClientContext);
    const [loginData, setLoginData] = useState({
        wsHost: clientState.wsHost || 'wss://.../ws',
        username: '',
        password: '',
    });

    // const { authRejected } = clientState;

    // if (authRejected) {
    //     clientDispatch({
    //         type: SET_CLIENT_STATE,
    //         payload: {
    //             isAuthorized: false,
    //             accessToken: null,
    //         }
    //     });
    // }

    const handleSubmit = e => {
        e.preventDefault();

        if (formIsValid()) {
            clientDispatch({
                type: SET_CLIENT_STATE,
                payload: {
                    ...loginData,
                    isConnected: false,
                    isConnecting: false,
                },
            });
        } else {
            clientDispatch({
                type: SET_CLIENT_STATE,
                payload: {
                    errorMessage: 'Fill all form fields!',
                },
            });
        }
    };

    const handleChange = ({ target }) => {
        setLoginData({
            ...loginData,
            [target.name]: target.value,
        });
    };

    const formIsValid = () => {
        const { wsHost, username, password } = loginData;
        return validateHostUrl(wsHost) && username && password;
    };

    // clearAuth = () => {
    //     console.info('REJECTED! Clearing auth state');
    //     // localStorage.removeItem('authToken');
    //     // this.props.setClientState({ authTokenIsSet: false, authRejected: false });
    // };

    const { accessToken, wsHost, isConnecting, errorMessage } = clientState;

    return (
        <>
            {/*
            {!isConnected && <Icon margin name="wifi-off" />}
            {isConnected && !isAuthorized && <Icon margin name="user-x" />}
            {isConnected && isAuthorized && <Icon margin name="wifi" />}

            {!isConnected &&
            accessToken && (
                    <span className={styles.overlay}>
                        <span className={styles.messageWrapper}>
                            <Icon width="60" height="60" name="wifi-off" />
                            <span className={styles.message}>Connecting to server...</span>
                        </span>
                    </span>
                )}
            */}

            {isConnecting && <h1>Connecting...</h1>}

            {(!accessToken || !wsHost) && !isConnecting && (
                <div className={styles.formWrapper}>
                    <Icon
                        className={styles.largeIcon}
                        width="60"
                        height="60"
                        name="untitled/padlock-2"
                    />

                    {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}

                    <form className={styles.form} onSubmit={handleSubmit}>
                        <label className={styles.label}>
                            Server address&nbsp;
                            {window.location.protocol === 'https://' && (
                                <small>(must start with wss://)</small>
                            )}
                        </label>
                        <input
                            className={styles.input}
                            type="url"
                            placeholder="Server address"
                            name="wsHost"
                            autoCorrect="off"
                            autoCapitalize="none"
                            onChange={handleChange}
                            value={loginData.wsHost}
                        />

                        <label className={styles.label}>User name</label>
                        <input
                            className={styles.input}
                            type="text"
                            placeholder="Username"
                            name="username"
                            autoCorrect="off"
                            autoCapitalize="none"
                            onChange={handleChange}
                            value={loginData.username}
                        />

                        <label className={styles.label}>Password</label>
                        <input
                            className={styles.input}
                            type="password"
                            placeholder="Password"
                            name="password"
                            onChange={handleChange}
                            value={loginData.password}
                        />

                        <input className={styles.submitButton} type="submit" value="OK" />

                        {/* <pre style={{ fontSize: '10px' }}>
                            {JSON.stringify(clientState, null, 2)}
                        </pre> */}
                    </form>
                </div>
            )}
        </>
    );
};
