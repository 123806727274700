import { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';

import styles from './NavBarExtra.scss';

type PropsType = {
    isVisible: boolean;
    hasBackground?: boolean;
    hasBorder?: boolean;
    children: ReactNode;
};

export const NavBarExtra = (props: PropsType) => {
    const { isVisible, hasBackground, hasBorder, children } = props;

    if (!isVisible) {
        return null;
    }

    const wrapperClassName = classnames(styles.wrapper, {
        [styles.hasBackground]: hasBackground,
        [styles.hasBorder]: hasBorder,
    });

    return ReactDOM.createPortal(
        <div className={wrapperClassName}>{children}</div>,
        document.getElementById('navbarExtended')!
    );
};
