import React from 'react';
import classnames from 'classnames';
import { Icon } from 'src/components/Icon/Icon';
import { IconButton } from 'src/components/IconButton/IconButton';

import styles from './ActionMenuHeader.scss';

type PropsType = {
    className?: string;
    iconName?: string;
    title: string;
    showClose?: boolean;
    onClose?: () => void;
};

export const ActionMenuHeader = (props: PropsType) => {
    const { className, iconName, title, showClose = true, onClose } = props;
    return (
        <div className={classnames(styles.wrapper, className)}>
            {iconName ? <Icon name={iconName} /> : <span />}
            {title && <span className={styles.title}>{title}</span>}
            {showClose && <IconButton iconName="mdi/window-close" onClick={onClose} />}
        </div>
    );
};
