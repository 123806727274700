import cloneDeep from 'lodash.clonedeep';
import merge from 'lodash.merge';

export const SET_LIBRARY_STATE = 'SET_LIBRARY_STATE';

export const libraryReducer = (state, action) => {
    const { type, payload } = action;
    // console.log('libraryReducer', state, action);

    switch (type) {
        case SET_LIBRARY_STATE:
            return merge(
                cloneDeep(state),
                payload
            );

        default:
            return state;
    }
};
