import React, { createContext, useReducer } from 'react';
import { libraryReducer } from '../reducers/libraryReducer';


const initialState = {};

export const LibraryContext = createContext({});

export const LibraryContextProvider = ({ children }) => {
    const [libraryState, libraryDispatch] = useReducer(libraryReducer, initialState);

    return <LibraryContext.Provider value={{ libraryState, libraryDispatch }}>{children}</LibraryContext.Provider>;
};

