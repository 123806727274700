import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import get from 'lodash.get';
import { ZigbeeManager } from './ZigbeeManager/ZigbeeManager';
import { emitRaw } from '../../actions/actions';
import { controller } from '../../constants';
import { useState } from 'react';

// import styles from './DeviceManager.module.scss';

export const DeviceManager = ({ uid, data, stateName }) => {
    const [isDeleted, setIsDeleted] = useState(false);
    const isZigbeeDevice = get(data, 'info.deviceSubType', []).includes('zigbee');

    const deleteDevice = useCallback(() => {        
        emitRaw({
            topic: 'master.deleteState',
            payload: {
                path: `${stateName}.${uid}`,
            },
        });
        setIsDeleted(true);
    }, [uid, emitRaw]);

    return !isDeleted && (
        <div>
            <h4>Device manager</h4>
            <button className="btn btn-xs" onClick={deleteDevice}>
                Clear device state
            </button>

            {isZigbeeDevice && <ZigbeeManager uid={uid} data={data} />}

            {/*<pre>{JSON.stringify(data)}</pre>*/}
        </div>
    );
};

DeviceManager.propTypes = {};
