import React, { useCallback, useContext } from 'react';
import { UiContext } from 'src/context/uiContext';
import { SET_SETTINGS_VISIBILITY } from 'src/reducers/uiReducer';
import { Icon } from '../Icon/Icon';
import { IconButton } from '../IconButton/IconButton';

import styles from './toolbar.scss';

export default props => {
    const { title, showMenu } = props;
    const {
        uiState: { settingsIsVisible },
        uiDispatch,
    } = useContext(UiContext);

    const showSettings = useCallback(() => {
        uiDispatch({
            type: SET_SETTINGS_VISIBILITY,
            payload: !settingsIsVisible,
        });
    }, [uiDispatch, settingsIsVisible]);

    return (
        <div className={styles.wrapper}>
            <div></div>
            <div className={styles.title}>{title}</div>

            <IconButton
                className={styles.iconSettings}
                iconName="mdi/settings"
                onClick={showSettings}
            />
        </div>
    );
};
