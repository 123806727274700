import React from 'react';
import get from 'lodash.get';
import { Button } from '../Button/Button';
import { emitAction } from '../../actions/actions';
import { getChannelFromState } from '../../helpers/stateHelper';

import styles from './Recuperator.scss';

const Recuperator = props => {
    const { state, uid, controller, withConfirmation } = props;
    const speed = get(state, `${props.uid}.recuperator.speed`);
    const channel = getChannelFromState({ state, uid, controller });
    const action = 'setSpeed';

    const getButtonProps = speedValue => ({
        icon: 'icon54/air-conditioner.1.1',
        type: speed === speedValue ? 'primary' : undefined,
        action: 'setSpeed',
        controller: 'recuperator',
        isDisabled: !channel,
        withConfirmation,
        onClick: () => emitAction({ channel, uid, controller, action, value: speedValue }),
    });

    return (
        <div className={styles.wrapper}>
            <Button title="1" {...getButtonProps(0)} />
            <Button title="Auto" {...getButtonProps(1)} />
            <Button title="2" {...getButtonProps(2)} />
            <Button title="3" {...getButtonProps(3)} />
        </div>
    );
};

export default Recuperator;
