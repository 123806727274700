import {
    createContext,
    forwardRef,
    ReactNode,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
} from 'react';
import classnames from 'classnames';
import { SwiperSlidePropsType } from './SwiperSlide';

import styles from './Swiper.scss';

type OnSwipeResp = {
    key: string;
    entry: IntersectionObserverEntry;
};

type SwiperPropsType = {
    children: ReactNode;
    initialActiveKey?: string;
    className?: string;
    innerClassName?: string;
    onChange: (arg0: OnSwipeResp) => void;
    onClick?: () => void;
    ioOptions?: IntersectionObserverInit;
};

type SlideType = {
    _key: string;
    props: SwiperSlidePropsType;
    node: HTMLElement;
    setIsActive: (arg0: boolean) => void;
};

export const SwiperContext = createContext({} as any);

// const getElementIndex = (node: Element | null | undefined) => {
//     var index = 0;
//     while ((node = node?.previousElementSibling)) {
//         index++;
//     }
//     return index;
// };

export const Swiper = forwardRef((props: SwiperPropsType, swiperRef) => {
    const {
        children,
        className,
        innerClassName,
        initialActiveKey,
        onChange = () => {},
        onClick = () => {},
        ioOptions = {},
    } = props;
    const slidesRef = useRef<SlideType[]>([]);
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const observer = useRef<IntersectionObserver | null>(null);
    const activeSlideKeyRef = useRef<string | undefined>(initialActiveKey);

    const swipeTo = useCallback((key: string) => {
        console.log(`swipeTo: ${key}`);
        // const { offsetLeft = 0 } = slidesRef.current?.[i]?.node || {};
        const targetSlide = slidesRef.current.find(slide => slide._key === key);
        if (targetSlide && wrapperRef.current !== null) {
            const { offsetLeft = 0 } = targetSlide.node;
            wrapperRef.current.scrollLeft = offsetLeft + 1;
        }
    }, []);

    useImperativeHandle(swiperRef, () => ({
        swipeTo,
    }));

    const onIntersect = useCallback(
        entries => {
            let entry;
            let key;

            for (let e of entries) {
                if (e.intersectionRatio > 0.9) {
                    entry = e;
                    key = e.target.dataset.key;
                    break;
                }
            }

            // on Slide change
            if (key && activeSlideKeyRef.current !== key) {
                activeSlideKeyRef.current = key;

                // set active/inactive state for each observed slide
                for (let e of entries) {
                    const slide = slidesRef.current.find(slide => slide.node === e.target);
                    if (slide) {
                        slide.setIsActive(e.target === entry.target);
                        // console.log(slide?.node.innerText, e.target === entry.target);
                    }
                }

                onChange({ key, entry });
            }
        },
        [onChange, activeSlideKeyRef]
    );

    const registerSlide = useCallback(
        (slide: SlideType) => {
            if (observer.current && slidesRef.current) {
                slidesRef.current.push(slide);
                observer.current.observe(slide.node);
                // not sure about adding order so just scroll on every child added
                initialActiveKey && swipeTo(initialActiveKey);
                return true;
            }
        },
        [observer.current]
    );

    useEffect(() => {
        if (slidesRef.current && slidesRef.current.length && initialActiveKey) {
            swipeTo(initialActiveKey);
        }
    }, [initialActiveKey, slidesRef]);

    useEffect(() => {
        observer.current = new IntersectionObserver(onIntersect, {
            root: wrapperRef.current,
            // rootMargin: '0% 0% 0% 0%',
            threshold: Array.from(Array(100).keys(), i => i / 100),
            ...ioOptions,
        });
        // observer.current = new IntersectionObserver(console.log, ioOptions);
        const { current: currentObserver } = observer;

        return () => currentObserver.disconnect();
    }, []);

    const wrapperClassNames = classnames(styles.wrapper, className);
    const innerClassNames = classnames(styles.inner, innerClassName);

    return (
        <SwiperContext.Provider value={{ registerSlide }}>
            <div className={wrapperClassNames} onClick={onClick}>
                <div className={styles.clip}>
                    <div className={innerClassNames} ref={wrapperRef}>
                        {children}
                    </div>
                </div>
            </div>
        </SwiperContext.Provider>
    );
});
