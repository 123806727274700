import React from 'react';
import { Icon } from 'src/components/Icon/Icon';

import styles from './ActionMenuListItem.scss';

type PropsType = {
    children: React.ReactNode;
    icon?: string;
    onClick: () => void;
};

export const ActionMenuListItem = (props: PropsType) => {
    const { children, icon: iconName, onClick } = props;
    return (
        <li className={styles.wrapper} onClick={onClick}>
            {iconName && <Icon name={iconName} className={styles.icon} />}
            {children}
        </li>
    );
};
