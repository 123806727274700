import React, { createElement, useContext, useCallback, memo } from 'react';
import isEqual from 'react-fast-compare';
import { hashCode } from '../../helpers/utils';
import { getChannelFromState } from '../../helpers/stateHelper';
import { emitAction } from '../../actions/actions';

// components
import { Button, ButtonComponent } from '../Button/Button';
import LedButton from '../LedButton/LedButton';
import Header from '../Head/Head';
import Heading from '../Heading/Heading';
import { UiContext } from '../../context/uiContext';
import { SET_COLOR_PICKER_STATE } from '../../reducers/uiReducer';
import BlindButton from '../BlindButton/BlindButton';
import Recuperator from '../Recuperator/Recuperator';
import { useDeviceState } from 'src/hooks/useDeviceState';
import { Swiper } from '../Swiper/Swiper';
import { SwiperSlide } from '../Swiper/SwiperSlide';

import styles from './componentBuilder.scss';

const components = { Header, Heading };

const DynamicComponentMemoized = memo(
    ({ tag: CustomTag, ...componentProps }) => <CustomTag {...componentProps} />,
    (prevProps, nextProps) => {
        return isEqual(
            { info: prevProps.info, state: prevProps.state },
            { info: nextProps.info, state: nextProps.state }
        );
    }
);

components.Wrapper = obj => {
    const { nodes = [] } = obj;
    return (
        <div className={styles.generalWrapper}>
            <ComponentBuilder config={nodes} />
        </div>
    );
};

components.Carousel = obj => {
    const { nodes = [] } = obj;
    return (
        <Swiper>
            <ComponentBuilder config={nodes} />
        </Swiper>
    );
};

const ColorPicker = props => {
    const { uiDispatch } = useContext(UiContext);

    const { uid, controller, address, action, title, icon, style, className, state } = props;

    const channel = getChannelFromState({ state, uid, controller });

    const onChange = useCallback(
        ({ rgb }) => {
            const value = Array.from(rgb);
            // console.log({ uid, controller, address, action, value });
            console.log(value);
            emitAction({ uid, controller, address, action, value });
        },
        [channel, uid, controller, address, action]
    );

    const showColorPicker = useCallback(() => {
        uiDispatch({
            type: SET_COLOR_PICKER_STATE,
            payload: {
                isVisible: true,
                onChange,
            },
        });
    }, [uiDispatch, onChange]);

    return (
        <Button
            // type={'primary'}
            icon={icon}
            title={title}
            style={style}
            className={className}
            onClick={showColorPicker}
        />
    );
};

components.CarouselSlide = obj => {
    const { _key, nodes = [], title } = obj;
    const titleEl = title && (
        <Heading className={styles.carouselSlideTitle} size={3} text={title} />
    );

    // TODO: each element should have uid and it must be used as _key prop
    return (
        <SwiperSlide title={titleEl} _key={_key}>
            <ComponentBuilder config={nodes} />
        </SwiperSlide>
    );
};

components.Button = ButtonComponent;
components.LedButton = LedButton;
components.BlindButton = BlindButton;
components.Recuperator = Recuperator;
components.ColorPicker = ColorPicker;

const DynamicComponent = (props, index) => {
    const CustomTag = components[props.component];
    const { nodes, key, props: componentProps = {} } = props;
    const { state, info, dispatch } = useDeviceState(componentProps);

    return (
        <DynamicComponentMemoized
            key={key}
            _key={key}
            tag={CustomTag}
            info={info}
            state={state}
            dispatch={dispatch}
            nodes={nodes}
            {...componentProps}
        />
    );
};

export const ComponentBuilder = ({ config = [] }) => {
    return config.map(DynamicComponent);
};
