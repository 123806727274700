export const SET_CLIENT_STATE = 'SET_CLIENT_STATE';

export const clientReducer = (state, action) => {
    const { type, payload } = action;
    // console.log('clientReducer', state, action);

    switch (type) {
        case SET_CLIENT_STATE: {
            if (typeof payload.wsHost === 'string') {
                localStorage.setItem('wsHost', state.wsHost);
            }

            if (typeof payload.accessToken === 'string') {
                localStorage.setItem('accessToken', payload.accessToken);
            }

            return {
                ...state,
                ...action.payload,
                isAuthorized: !!payload.accessToken,
            };
        }

        default:
            return state;
    }
};

// import localStorage from '../helpers/localStorage';
// import cloneDeep from 'lodash.clonedeep';
// import merge from 'lodash.merge';
//
// export const SET_CLIENT_STATE = 'SET_CLIENT_STATE';
//
// const initialState = {
//     accessTokenIsSet: !!localStorage.getItem('accessToken'),
// };
// let clonedState;
//
// const clientReducer = (state = initialState, { type, payload = {} }) => {
//     switch (type) {
//         case 'REPLACE_CLIENT_STATE':
//             return payload;
//
//         case SET_CLIENT_STATE:
//             cloneState(state);
//
//             if (payload.accessToken) {
//                 payload.accessTokenIsSet = true;
//                 localStorage.setItem('accessToken', payload.accessToken);
//             }
//
//             return merge(clonedState, payload);
//
//         default:
//             return state;
//     }
// };
//
// const cloneState = state => {
//     if (!state) {
//         console.error('No current state provided!');
//     }
//     clonedState = cloneDeep(state);
// };
//
// export default clientReducer;
