import React from 'react';
import SVG from 'react-inlinesvg';

export const Icon = props => {
    const {
        name,
        width = 24,
        height = 24,
        strokeWidth = 1.2,
        style = {},
        className,
        ...rest
    } = props;

    const [path, fileName] = name.split('/');

    if (!path || !fileName) {
        console.error(`Icon not found: ${name} `);
        return null;
    }

    return (
        <SVG
            width={width}
            height={height}
            className={className}
            strokeWidth={strokeWidth}
            src={`https://icons.g1.lt/${path}/${fileName}.svg`}
            {...rest}
        />
    );
};
