import React, { useState, useContext } from 'react';
import get from 'lodash.get';
import sortby from 'lodash.sortby';
import { filterByController } from '../../helpers/filterDeviceState';
import { DebugDevice } from './DebugDevice';
import { DebugObjectSize } from './DebugObjectSize';
import { stateName as stateNames } from '../../constants';
import { Icon } from '../Icon/Icon';

import { ClientContext } from '../../context/clientContext';
import { DeviceContext } from '../../context/deviceContext';
import { TriggerContext } from '../../context/triggerContext';
import { LibraryContext } from '../../context/libraryContext';
import { PlayerContext } from '../../context/playerContext';
import { TorrentContext } from '../../context/torrentContext';
import { AutomationContext } from '../../context/automationContext';

import styles from './DebugStates.scss';

export const DebugStates = props => {
    const { title, stateName, controllerList, selector, initialVisible } = props;
    const [isVisible, setIsVisible] = useState(initialVisible);

    const state = {
        [stateNames.AUTOMATION_STATE]: useContext(AutomationContext).automationState,
        [stateNames.CLIENT_STATE]: useContext(ClientContext).clientState,
        [stateNames.DEVICE_STATE]: useContext(DeviceContext).deviceState,
        [stateNames.TRIGGER_STATE]: useContext(TriggerContext).triggerState,
        [stateNames.LIBRARY_STATE]: useContext(LibraryContext).libraryState,
        [stateNames.PLAYER_STATE]: useContext(PlayerContext).playerState,
        [stateNames.TORRENT_STATE]: useContext(TorrentContext).torrentState,
    };

    let obj = props;
    let deviceUid;

    if (controllerList) {
        obj = filterByController(props.deviceState, controllerList);
    } else if (stateName) {
        obj = get(state, stateName) || {};
    }

    if (selector && selector !== '') {
        obj = get(obj, selector) || {};
        deviceUid = selector.split('.')[0];
    }

    const uids = sortby(
        Object.keys(obj).map(key => ({
            key,
            sortName: get(obj[key], 'info.friendlyName', '') + key,
        })),
        'sortName'
    );

    return (
        <div className={styles.wrapper}>
            <h3 className={styles.title} onClick={() => setIsVisible(!isVisible)}>
                DEBUG STATE: {title || stateName}
                <DebugObjectSize obj={obj} />
                <Icon
                    className={styles.titleIcon}
                    width="14"
                    name={isVisible ? 'mdi/chevron-down' : 'mdi/chevron-right'}
                />
            </h3>

            {isVisible &&
                uids.map(({ key }) => {
                    return (
                        <DebugDevice
                            key={key}
                            name={key}
                            uid={deviceUid || key}
                            data={obj[key]}
                            state={{ [key]: obj[key] }}
                            dataPath={key}
                            stateName={stateName}
                        />
                    );
                })}
        </div>
    );
};
