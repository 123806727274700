import React, { useState } from 'react';
import Draggable from 'react-draggable';
import { Icon } from '../Icon/Icon';

import styles from './DragConfirm.scss';

export const DragConfirm = ({
    minPercent = 85,
    title = 'Are you sure?',
    hint = 'Swipe to confirm',
    onSuccess,
    onFail,
    onCancelClick = () => {},
}) => {
    let handleWrapperEl;
    let handleEl;
    const [left, setLeft] = useState(0);

    const onStop = (e, position) => {
        const { x } = position;
        const handleWrapperWidth = handleWrapperEl.clientWidth;
        const handleWidth = handleEl.clientWidth;
        const percent = (x / (handleWrapperWidth - handleWidth)) * 100;
        const success = percent >= minPercent;

        console.debug({ success, percent });

        if (success) {
            setLeft(x);
            typeof onSuccess === 'function' && setTimeout(() => onSuccess({ percent }));
        } else {
            setLeft(0);
            onFail === 'function' && setTimeout(() => onFail({ percent }));
        }

    };

    return (
        <div className={styles.overlay}>
            <div className={styles.wrapper}>
                <h3 className={styles.title}>{title}</h3>
                <div className={styles.handleWrapper} ref={el => (handleWrapperEl = el)}>
                    <div className={styles.hint}>{hint}</div>
                    <Draggable
                        defaultClassName={styles.handle}
                        defaultClassNameDragging={styles.handleDragging}
                        defaultClassNameDragged={styles.handleDragged}
                        axis="x"
                        position={{ x: left, y: 0 }}
                        bounds={`.${styles.wrapper}`}
                        onStop={onStop}
                    >
                        <div ref={el => (handleEl = el)}>
                            <Icon className={styles.icon} name="mdi/arrow-right" />
                        </div>
                    </Draggable>

                </div>

                <div className={`btn ${styles.cancelBtn}`} onClick={() => setTimeout(onCancelClick)}>
                    Cancel
                </div>
            </div>
        </div>
    );
};
