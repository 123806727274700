import React, { Fragment, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { DragConfirm } from '../DragConfirm/DragConfirm';
import { Animate } from '../Animate/Animate';
import { Icon } from '../Icon/Icon';

import { getChannelFromState, getValueFromState } from '../../helpers/stateHelper';
import { emitAction } from '../../actions/actions';

import styles from './button.scss';
import { useLongPress } from 'src/hooks/useLongPress';
import { ActionMenu } from '../ActionMenu/ActionMenu';
import { ActionMenuHeader } from '../ActionMenu/ActionMenuHeader/ActionMenuHeader';
import { DeviceActionMenu } from '../DeviceActionMenu/DeviceActionMenu';

const types = {
    default: styles.default,
    primary: styles.primary,
    secondary: styles.secondary,
    disabled: styles.disabled,
};

export const Button = React.memo(props => {
    const {
        type = 'default',
        icon = 'HelpCircle',
        title,
        onClick: onClickFn = () => {},
        onHold = () => {},
        children,
        isDisabled,
        withConfirmation,
        style,
        className,
    } = props;

    const [confirmationVisible, setConfirmationVisible] = useState(false);

    const onClick = () => {
        console.log('onClick');
        if (isDisabled) {
            return;
        }

        if (withConfirmation) {
            setConfirmationVisible(true);
        } else {
            onClickFn();
        }
    };

    const longPressEvent = useLongPress({ onHold, onClick });

    const classNames = classnames(styles.wrapper, className, `button-${type}`, {
        [types[type]]: !!types[type],
        [styles.disabled]: isDisabled,
    });

    const ConfirmationEl = withConfirmation ? (
        <Animate in={confirmationVisible}>
            <DragConfirm
                onSuccess={() => {
                    onClickFn();
                    setConfirmationVisible(false);
                }}
                onCancelClick={() => setConfirmationVisible(false)}
            />
        </Animate>
    ) : null;

    return (
        <>
            {ConfirmationEl}
            <div className={classNames} style={style} {...longPressEvent}>
                <Icon name={icon} className={styles.icon} width={25} height={25} />
                <span className={styles.title}>{title}</span>
                {children}
            </div>
        </>
    );
});

export const ButtonComponent = props => {
    const {
        uid,
        controller,
        address,
        selector = 'value',
        action,
        value,
        invert,
        withConfirmation,
        title,
        icon,
        style,
        className,
        state,
        info,
        // debounceTime = 500, // TODO: disable button for n seconds after click
    } = props;
    const [isVisibleActionMenu, setIsVisibleActionMenu] = useState(false);

    // const currentValue = getValueFromState({ state, uid, controller, address, selector });
    const currentValue = selector ? state?.[selector] : state;
    const isActive = currentValue === !invert;
    const channel = getChannelFromState({ state, uid, controller });

    // const isDisabled = !channel;
    const isDisabled = false;

    useEffect(() => {
        // return () => console.log('button unmounted', title);
    }, [title]);

    const showActionMenu = useCallback(() => {
        setIsVisibleActionMenu(true);
    }, [setIsVisibleActionMenu]);

    const onClick = useCallback(() => {
        emitAction({ uid, controller, address, action, value });
        // console.log('ON CLICK');
        // setIsTrue(!isTrue);
    }, [emitAction, uid, controller, address, action, value]);

    return (
        <>
            <DeviceActionMenu
                title={title}
                isVisible={isVisibleActionMenu}
                uid={uid}
                controller={controller}
                address={address}
                action={action}
                value={value}
                state={state}
                info={info}
                onClose={() => setIsVisibleActionMenu(false)}
            />
            <Button
                type={isActive ? 'primary' : undefined}
                icon={icon}
                title={title}
                style={style}
                className={className}
                isDisabled={isDisabled}
                withConfirmation={withConfirmation}
                onHold={showActionMenu}
                onClick={onClick}
            />
        </>
    );
};
