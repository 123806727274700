import { Context, createContext, useContext } from 'react';
import { ClientContext } from '../context/clientContext';
import { DeviceContext } from '../context/deviceContext';
import { LibraryContext } from '../context/libraryContext';
import { PlayerContext } from '../context/playerContext';
import { TorrentContext } from '../context/torrentContext';

type OptionsType = {
    stateName: string;
    uid: string;
    controller: string;
    address: string | number;
};

const contexts: Record<string, Context<any>> = {
    clientState: ClientContext,
    deviceState: DeviceContext,
    libraryState: LibraryContext,
    playerState: PlayerContext,
    torrentState: TorrentContext,
    dummyState: createContext({}),
};

export const useDeviceState = (options: OptionsType) => {
    const { stateName = 'dummyState', uid, controller, address } = options;
    const context = useContext(contexts[stateName]);

    if (context && uid && controller && address) {
        const name = stateName.replace('State', '');
        // const state = context[stateName];
        // console.log({ state: context[stateName], stateName, uid, controller, address });
        const state = context[stateName]?.[uid]?.[controller]?.[address];
        const info = context[stateName]?.[uid]?.info;
        const dispatch = context[`${name}Dispatch`];
        return { state, info, dispatch };
    } else {
        return {};
    }
};
