import React from 'react';
import classnames from 'classnames';

import styles from './heading.scss';

export default props => {
    const { size = 2, text, className } = props;
    const Tag = ['h1', 'h2', 'h3', 'h4', 'h5'][size - 1];
    const classNames = classnames(styles[`heading${size}`], className);

    if (!Tag) {
        return null;
    }

    return <Tag className={classNames}>{text}</Tag>;
};
