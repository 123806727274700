import { useEffect, useState } from 'react';
import { NavBarExtra } from 'src/components/NavBar/NavBarExtra/NavBarExtra';
import { NavBarExtraButton } from 'src/components/NavBar/NavBarExtra/NavBarExtraButton/NavBarExtraButton';
import { NavBarExtraButtonWrapper } from 'src/components/NavBar/NavBarExtra/NavBarExtraButton/NavBarExtraButtonWrapper';

type PropsType = {
    navBarIsExpanded: boolean;
    roomsConfig: any[];
    setHomeControlls: (arg0: any) => void;
    initialRoom: string;
};

export const HomeExtraNavBar = (props: PropsType) => {
    const { navBarIsExpanded, roomsConfig, setHomeControlls, initialRoom } = props;

    const [activeRoom, setActiveRoom] = useState(initialRoom);

    useEffect(() => {
        const activeRoom = roomsConfig.find(room => room.key === initialRoom);
        setHomeControlls(activeRoom);
    }, [roomsConfig, initialRoom]);

    return (
        <NavBarExtra isVisible={navBarIsExpanded} hasBackground>
            <NavBarExtraButtonWrapper>
                {roomsConfig.map(room => {
                    const { key, iconName, title } = room;
                    return (
                        <NavBarExtraButton
                            key={key}
                            isActive={key === activeRoom}
                            iconName={iconName}
                            title={title}
                            onClick={() => {
                                setActiveRoom(key);
                                setHomeControlls(room);
                            }}
                        />
                    );
                })}
            </NavBarExtraButtonWrapper>
        </NavBarExtra>
    );
};
