import React from 'react';
import classnames from 'classnames';

import styles from './ActionMenuList.scss';

type PropsType = {
    children?: React.ReactNode;
    className?: string;
};

export const ActionMenuList = (props: PropsType) => {
    const { children, className } = props;
    return <ul className={classnames(styles.wrapper, className)}>{children}</ul>;
};
