import React, { createContext, useReducer } from 'react';
import { torrentReducer } from '../reducers/torrentReducer';


const initialState = {};

export const TorrentContext = createContext({});

export const TorrentContextProvider = ({ children }) => {
    const [torrentState, torrentDispatch] = useReducer(torrentReducer, initialState);

    return <TorrentContext.Provider value={{ torrentState, torrentDispatch }}>{children}</TorrentContext.Provider>;
};

