import { FC } from 'react';
import classnames from 'classnames';
import { Icon } from '../Icon/Icon';

import styles from './IconButton.scss';

type PropsType = {
    iconName: string;
    className?: string;
    iconClassName?: string;
    onClick?: () => void;
};

export const IconButton: FC<PropsType> = (props: PropsType) => {
    const { iconName, className, iconClassName, onClick = () => {} } = props;
    return (
        <div className={classnames(styles.wrapper, className)} onClick={onClick}>
            <Icon className={classnames(styles.icon, iconClassName)} name={iconName} />
        </div>
    );
};
