import React, { createContext, Dispatch, ReactNode, useReducer } from 'react';
import { uiReducer } from '../reducers/uiReducer';
import localStorage from '../helpers/localStorage';

export const ACTIVE_PLAYER_UID_KEY = 'activePlayerUid';

type ActionType = {
    type: string;
    payload: any;
};

type UiContextType = {
    uiState: any;
    uiDispatch: Dispatch<ActionType>;
};

const initialState = {
    navBar: {
        isExpanded: false,
    },
    router: {
        pathname: null,
        search: null,
        hash: null,
    },
    colorPicker: {
        isVisible: false,
    },
    foregroundState: null,
    [ACTIVE_PLAYER_UID_KEY]: localStorage.getItem(ACTIVE_PLAYER_UID_KEY),
    settingsIsVisible: false,
};

export const UiContext = createContext<UiContextType>({} as UiContextType);

export const UiContextProvider = ({ children }: { children: ReactNode }) => {
    const [uiState, uiDispatch] = useReducer(uiReducer, initialState);

    return <UiContext.Provider value={{ uiState, uiDispatch }}>{children}</UiContext.Provider>;
};
