import React, { memo, useState, useContext, useEffect } from 'react';
import { useSwipeable, UP, DOWN } from 'react-swipeable';
import { useDebouncedCallback } from 'use-debounce';
import get from 'lodash.get';

import { useSwipeArea } from '../../../hooks/useSwipeArea';
import { VolumeBar } from '../VolumeBar/VolumeBar';

import { emitAction } from '../../../actions/actions';
import { UiContext } from '../../../context/uiContext';
import { PlayerContext } from '../../../context/playerContext';
import { SET_NAV_BAR_STATE } from '../../../reducers/uiReducer';
import isEqual from 'react-fast-compare';

import styles from './NavBarSwipeArea.scss';

let initialVolumeValue = 0;
let setVolumeTimer;

// TODO: fix memo prevProps/nextProps change
export const NavBarSwipeAreaComponent = memo(props => {
    const { activePlayerUid, stateVolumeValue, uiDispatch, onClick = () => {} } = props;
    const { getPercentage } = useSwipeArea();
    const [volumeValue, setVolumeValue] = useState(initialVolumeValue);
    const [inProcess, setInProcess] = useState(false);

    const [debouncedEmit] = useDebouncedCallback(
        value => {
            emitAction({
                uid: activePlayerUid,
                controller: 'player',
                action: 'SET_VOLUME',
                // value: value / 100,
                value: { level: value / 100 },
            });
            setVolumeValue(value);
        },
        200,
        // The maximum time func is allowed to be delayed before it's invoked:
        { maxWait: 200 }
    );

    const onSwiping = ({ deltaX, deltaY, event }) => {
        setInProcess(true);
        const el = event.target;

        // invert deltaX
        // https://github.com/dogfessional/react-swipeable/issues/166
        const { deltaPX } = getPercentage({ el, deltaX: deltaX * -1, deltaY });
        const newValue = Math.round(initialVolumeValue + deltaPX);

        setVolumeValue(newValue);
        debouncedEmit(newValue);
    };

    const onSwiped = data => {
        const { dir, absY } = data;
        onSwiping(data);

        initialVolumeValue = volumeValue;

        setInProcess(false);

        if ([UP, DOWN].includes(dir) && absY > 30) {
            uiDispatch({
                type: SET_NAV_BAR_STATE,
                payload: { isExpanded: dir === UP },
            });
        }
    };

    useEffect(() => {
        clearTimeout(setVolumeTimer);
        if (!inProcess) {
            initialVolumeValue = stateVolumeValue;
            setVolumeTimer = setTimeout(() => {
                setVolumeValue(stateVolumeValue);
            }, 1200);
        }
    }, [stateVolumeValue, inProcess]);

    const handlers = useSwipeable({
        onSwiping,
        onSwiped,
        trackMouse: true,
        preventDefaultTouchmoveEvent: true,
    });

    return (
        <>
            <VolumeBar
                wrapperClassName={styles.volumeBar}
                value={volumeValue}
                inProcess={inProcess}
            />
            <div className={styles.wrapper} {...handlers} onClick={onClick} />
        </>
    );
}, isEqual);

export const NavBarSwipeArea = memo(props => {
    const {
        uiState: { activePlayerUid },
        uiDispatch,
    } = useContext(UiContext);
    const { playerState } = useContext(PlayerContext);
    const stateVolumeValue = get(playerState, `['${activePlayerUid}'].volume.master.value`);

    return (
        <NavBarSwipeAreaComponent
            activePlayerUid={activePlayerUid}
            stateVolumeValue={stateVolumeValue}
            uiDispatch={uiDispatch}
            {...props}
        />
    );
}, isEqual);
