import cloneDeep from 'lodash.clonedeep';
import merge from 'lodash.merge';

export const SET_TRIGGER_STATE = 'SET_TRIGGER_STATE';
export const REPLACE_TRIGGER_STATE = 'REPLACE_TRIGGER_STATE';

export const triggerReducer = (state, action) => {
    const { type, payload } = action;
    // console.log('deviceReducer', state, action);

    switch (type) {
        case SET_TRIGGER_STATE:
            return merge(
                cloneDeep(state),
                payload
            );
        case REPLACE_TRIGGER_STATE:
            return payload;

        default:
            return state;
    }
};
