import { ActionMenu } from '../ActionMenu/ActionMenu';
import { ActionMenuHeader } from '../ActionMenu/ActionMenuHeader/ActionMenuHeader';
import { ActionMenuList } from '../ActionMenu/ActionMenuList/ActionMenuList';
import { ActionMenuListItem } from '../ActionMenu/ActionMenuListItem/ActionMenuListItem';
import { Icon } from '../Icon/Icon';

type PropsType = {
    title: string;
    isVisible: boolean;
    uid: string;
    address: string | number;
    action?: string;
    value?: any;
    info: any;
    state: any;
    onClose: () => void;
};

export const DeviceActionMenu = (props: PropsType) => {
    const { title, isVisible, uid, address, action, value, info, state, onClose } = props;

    const listIcon = <Icon name="mdi/circle-medium" />;

    return (
        <ActionMenu visible={isVisible} onClose={onClose}>
            <ActionMenuHeader title={title} onClose={onClose} />
            <ActionMenuList>
                <ActionMenuListItem onClick={() => {}}>
                    {listIcon} UID: {uid}
                </ActionMenuListItem>
                <ActionMenuListItem onClick={() => {}}>
                    {listIcon} Address: {address}
                </ActionMenuListItem>
                <ActionMenuListItem onClick={() => {}}>
                    {listIcon} Value: {value}
                </ActionMenuListItem>
                <ActionMenuListItem onClick={() => {}}>
                    {listIcon} Action: {action}
                </ActionMenuListItem>
            </ActionMenuList>

            <h3>Info</h3>
            <pre>{JSON.stringify(info, null, 4)}</pre>

            <h3>State</h3>
            <pre>{JSON.stringify(state, null, 4)}</pre>
        </ActionMenu>
    );
};
