import { ACTIVE_PLAYER_UID_KEY } from '../context/uiContext';

export const SET_UI_STATE = 'SET_UI_STATE';
export const SET_COLOR_PICKER_STATE = 'SET_COLOR_PICKER_STATE';
export const SET_NAV_BAR_STATE = 'SET_NAV_BAR_STATE';
export const SET_ROUTER_STATE = 'SET_ROUTER_STATE';
export const SET_FOREGROUND_STATE = 'SET_FOREGROUND_STATE';
export const SET_ACTIVE_PLAYER_UID = 'SET_ACTIVE_PLAYER_UID';
export const SET_SETTINGS_VISIBILITY = 'SET_SETTINGS_VISIBILITY';

export const uiReducer = (state, action) => {
    const { type, payload } = action;
    // console.log('uiReducer', state, action);

    switch (type) {
        case SET_UI_STATE: {
            return {
                ...state,
                ...payload,
            };
        }

        case SET_COLOR_PICKER_STATE:
            return {
                ...state,
                colorPicker: {
                    ...state.colorPicker,
                    ...payload,
                },
            };

        case SET_NAV_BAR_STATE:
            return {
                ...state,
                navBar: {
                    ...state.navBar,
                    ...payload,
                },
            };
        case SET_ROUTER_STATE:
            return {
                ...state,
                router: {
                    ...state.router,
                    ...payload,
                },
            };

        case SET_FOREGROUND_STATE:
            return {
                ...state,
                foregroundState: payload,
            };

        case SET_ACTIVE_PLAYER_UID: {
            localStorage.setItem(ACTIVE_PLAYER_UID_KEY, payload);
            return {
                ...state,
                activePlayerUid: payload,
            };
        }

        case SET_SETTINGS_VISIBILITY: {
            // console.log(SET_SETTINGS_VISIBILITY, payload);
            return {
                ...state,
                settingsIsVisible: payload,
            };
        }

        default:
            return state;
    }
};
