import React from 'react';

/**
 * The theme components only imports it's theme CSS-file. These components are lazy
 * loaded, to enable "code splitting" (in order to avoid the themes being bundled together)
 */

const ThemeLight = React.lazy(() => import('./ThemeLight'));
const ThemeDark = React.lazy(() => import('./ThemeDark'));

const Theme: React.FC = () => {
    const themeName = localStorage.getItem('themeName') || 'light';
    switch (themeName) {
        case 'dark':
            return <ThemeDark />;
        default:
            return <ThemeLight />;
    }
};

export const ThemeSelector: React.FC = ({ children }) => (
    <>
        {/* Conditionally render theme, based on the current client context */}
        <React.Suspense fallback={<div>Loading...</div>}>
            <Theme />
        </React.Suspense>
        {/* Render children immediately! */}
        {children}
    </>
);
