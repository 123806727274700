import React from 'react';
import classnames from 'classnames';

import { Icon } from '../../../Icon/Icon';

import styles from './SensorDiff.scss';

export const SensorDiff = ({ diff = 0 }) => {
    const iconClass = classnames(styles.icon, {
        [styles.inc]: diff > 0,
        [styles.desc]: diff < 0,
    });

    if (!diff) {
        return null;
    }

    return (
        <span className={styles.wrapper}>
            <Icon name="mdi/triangle" className={iconClass} width={15} height={10} />
        </span>
    );
};
