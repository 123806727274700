import React, { createContext, useReducer, useContext } from 'react';
import { deviceReducer } from '../reducers/deviceReducer';


const initialState = {};

export const DeviceContext = createContext({});

export const DeviceContextProvider = ({ children }) => {
    const [deviceState, deviceDispatch] = useReducer(deviceReducer, initialState);
    return <DeviceContext.Provider value={{ deviceState, deviceDispatch }}>{children}</DeviceContext.Provider>;
};

export const useDevice = () => useContext(DeviceContext);
