import cloneDeep from 'lodash.clonedeep';
import merge from 'lodash.merge';

export const SET_PLAYER_STATE = 'SET_PLAYER_STATE';

export const playerReducer = (state, action) => {
    const { type, payload } = action;
    // console.log('playerReducer', state, action);

    switch (type) {
        case SET_PLAYER_STATE:
            return merge(
                cloneDeep(state),
                payload
            );

        default:
            return state;
    }
};
