import React from 'react';
import { NavLink } from 'react-router-dom';
import { SensorValue } from './SensorValue';

import styles from './SensorLine.scss';
import isEqual from 'react-fast-compare';

export const SensorLine = React.memo(
    // ({ zoneName, href, temperature, humidity }) => {
    ({ zoneName, href, sensorData }) => {
        return (
            <NavLink to={href} className={styles.wrapper}>
                <span className={styles.zoneName}>{zoneName}</span>

                {/* <span className={styles.valuesWrapper}> */}
                {sensorData.temperature && (
                    <SensorValue
                        data={sensorData.temperature}
                        units="&deg;C"
                        className={styles.temperatureValueWrapper}
                    />
                )}

                {sensorData.humidity && (
                    <SensorValue
                        data={sensorData.humidity}
                        fractionDigits={0}
                        units="%"
                        className={styles.humidityValueWrapper}
                    />
                )}

                {sensorData.power && (
                    <div className={styles.value}>{sensorData.power.toLocaleString()}&nbsp;W</div>
                )}

                {sensorData.totalEnergy && (
                    <div className={styles.value}>
                        {sensorData.totalEnergy.toLocaleString()}&nbsp;kWh
                    </div>
                )}
            </NavLink>
        );
    },
    isEqual
    // (prevProps, nextProps) => {
    //     return isEqual(
    //         {
    //             temperatureValue: prevProps.temperature?.value,
    //             humidityValue: prevProps.humidity?.value,
    //         },
    //         {
    //             temperatureValue: nextProps.temperature?.value,
    //             humidityValue: nextProps.humidity?.value,
    //         }
    //     );
    // }
);
