import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './ZigbeeManager.module.scss';
import { emitAction } from '../../../actions/actions';
import { controller } from '../../../constants';

export const ZigbeeManager = ({ uid, data }) => {
    const { info = {} } = data;

    const [friendlyName, setFriendlyName] = useState(info.friendlyName || '');

    const renameDevice = useCallback(() => {
        emitAction({
            uid: 'zigbee2mqtt',
            controller: controller.ZIGBEE,
            action: 'renameDevice',
            data: {
                uid,
                friendlyName,
            },
        });
    }, [friendlyName, uid, emitAction]);

    return (
        <div className={styles.wrapper}>
            <label>Rename Zigbee Device</label>
            <input
                type="text"
                value={friendlyName}
                onChange={e => setFriendlyName(e.target.value)}
            />
            <button onClick={renameDevice}>OK</button>
        </div>
    );
};

ZigbeeManager.propTypes = {

};
