import React, {
    useCallback,
    useContext,
    useLayoutEffect,
    createRef,
    useState,
    useMemo,
    createElement,
    useEffect,
    useRef,
} from 'react';
import get from 'lodash.get';

import { UiContext } from '../../../context/uiContext';

// components
import { ComponentBuilder } from '../../ComponentBuilder/ComponentBuilder';
import Toolbar from '../../Toolbar/Toolbar';
import Head from '../../Head/Head';
import { Sensors } from './Sensors/Sensors';
import { DebugStates } from '../../DebugStates/DebugStates';
import { stateName } from '../../../constants';
import { Settings } from 'src/components/Settings/Settings';
import { useLongPress } from 'src/hooks/useLongPress';
import { HomeStateSwitch } from 'src/components/HomeStateSwitch/HomeStateSwitch';
import { useResize } from 'src/hooks/useResize';
import { Swiper } from 'src/components/Swiper/Swiper';
import { SwiperSlide } from 'src/components/Swiper/SwiperSlide';

import styles from './home.scss';
import { HomeExtraNavBar } from './homeExtraNavBar';
import { SET_NAV_BAR_STATE } from 'src/reducers/uiReducer';
import { TempDial } from 'src/components/TempDial/TempDial';

export const Home = () => {
    const { uiState, uiDispatch } = useContext(UiContext);
    const navBarIsExpanded = get(uiState, 'navBar.isExpanded');
    const uiSensors = get(uiState, 'sensors') || [];

    const roomsConfig = get(uiState, 'roomsConfig') || [];
    const [homeControlls, setHomeControlls] = useState([]);

    const [marginTop, setMarginTop] = useState(250);
    const [activeIndex, setActiveIndex] = useState(0);

    const swiperRef = useRef(null);

    const onHold = useCallback(ev => {
        console.log('onHold');
    }, []);

    const onSwipeChange = useCallback(({ index }) => {
        // console.log('index', index);
        setActiveIndex(index);
    }, []);

    const [headRef] = useResize({
        onResize: ([entry]) => {
            setMarginTop(entry.target.offsetHeight - 15);
        },
    });

    const longPressEvent = useLongPress({ onHold });

    return (
        <div className={styles.wrapper}>
            <Head
                className={styles.head}
                ref={headRef}
                bgImageSrc="/images/rooms/living-room-001.jpg"
            >
                <Toolbar title="SmartHome.Lt" />
                <Sensors sensors={homeControlls.sensors} />
            </Head>

            <div className={styles.content} style={{ marginTop: `${marginTop}px` }}>
                {/* <HomeStateSwitch /> */}
                <TempDial />
                <HomeExtraNavBar
                    // navBarIsExpanded={navBarIsExpanded}
                    navBarIsExpanded={true}
                    roomsConfig={roomsConfig}
                    setHomeControlls={setHomeControlls}
                    initialRoom={homeControlls?.key || roomsConfig?.[0]?.key}
                />
                {/* <br />
                <br />
                <br />

                <Swiper
                    onChange={onSwipeChange}
                    innerClassName={styles.tempSwiper}
                    ref={swiperRef}
                    initialIndex={1}
                >
                    <SwiperSlide _key="one" className={styles.tempSlide1}>one</SwiperSlide>
                    <SwiperSlide _key="two" className={styles.tempSlide2}>two</SwiperSlide>
                    <SwiperSlide _key="three" className={styles.tempSlide3}>three</SwiperSlide>
                </Swiper>

                <button onClick={() => swiperRef.current.swipeTo(0)}>0</button>
                <button onClick={() => swiperRef.current.swipeTo(1)}>1</button>
                <button onClick={() => swiperRef.current.swipeTo(2)}>2</button>

                <h3>{activeIndex}</h3> */}
                <div className={styles.componentBuilderWrapper}>
                    <ComponentBuilder key={homeControlls.key} config={homeControlls.nodes} />
                </div>
            </div>
        </div>
    );
};
