import React, { useContext, useCallback } from 'react';
import { ColorPicker } from './ColorPicker';


import { UiContext } from '../../context/uiContext';
import { SET_COLOR_PICKER_STATE } from '../../reducers/uiReducer';

import styles from './ColorPickerWindow.scss';


export const ColorPickerWindow = props => {
    const { uiState: { colorPicker }, uiDispatch } = useContext(UiContext);
    const {
        isVisible,
        onChange = () => {},
    } = colorPicker || {};

    const onOkClick = useCallback(() => {
        uiDispatch({
            type: SET_COLOR_PICKER_STATE,
            payload: { isVisible: false },
        });
    }, [uiDispatch]);

    if (!isVisible) {
        return null;
    }

    return (
        <div className={styles.overlay}>
            <div className={styles.wrapper}>
                <ColorPicker
                    className={styles.colorPicker}
                    onChange={onChange}
                />

                <button className="btn btn-wide" onClick={onOkClick}>OK</button>
            </div>
        </div>
    );
};
