import React, { createContext, useReducer } from 'react';
import { automationReducer } from '../reducers/automationReducer';


const initialState = {};

export const AutomationContext = createContext({});

export const AutomationContextProvider = ({ children }) => {
    const [automationState, automationDispatch] = useReducer(automationReducer, initialState);

    return <AutomationContext.Provider value={{ automationState, automationDispatch }}>{children}</AutomationContext.Provider>;
};

