import { Icon } from '../Icon/Icon';
import styles from './TempDial.scss';

type PropsType = {};

export const TempDial = (props: PropsType) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.upDownCircle}>
                <Icon name="icon54/up.4.2" className={styles.iconUp} />
                <div className={styles.valueCircle}>23</div>
                <Icon name="icon54/down.4.2" className={styles.iconDown} />
            </div>
        </div>
    );
};
