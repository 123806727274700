
let _el;
let _computedStyle;

const _getComputedStyle = el => {
    if (_el === el) {
        return _computedStyle;
    }
    const computedStyle = getComputedStyle(el);
    _computedStyle = {
        ...computedStyle,
        width: parseInt(computedStyle.width),
        height: parseInt(computedStyle.height),
    };
    return _computedStyle;
};

export const useSwipeArea = () => {
    return {
        getPercentage: ({ el, deltaX, deltaY }) => {
            const { width, height } = _getComputedStyle(el);
            const result = {};

            if (deltaX) {
                result.deltaPX = deltaX / width * 100;
            }
            if (deltaY) {
                result.deltaPY = deltaY / height * 100;
            }

            return result;
        }
    };
};
