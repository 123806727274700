import React from 'react';
import classnames from 'classnames';

import styles from './head.scss';

export default React.forwardRef((props, ref) => {
    const { bgImageSrc, children, className } = props;

    const wrapperClassName = classnames(styles.wrapper, className);

    return (
        <div
            ref={ref}
            className={wrapperClassName}
            style={{ backgroundImage: `url(${bgImageSrc})` }}
        >
            {children}
        </div>
    );
});
