export const filterByController = (deviceState, controllerList) => {
    const result = {};
    controllerList = Array.isArray(controllerList) ? controllerList : [controllerList];

    Object.entries(deviceState)
        .forEach(([uid, device]) => {

            controllerList.forEach(controller => {
                if (device[controller]) {
                    result[uid] = result[uid] || {};
                    result[uid][controller] = device[controller];
                }
            });

        });

    return result;
};
