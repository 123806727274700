import cloneDeep from 'lodash.clonedeep';
import merge from 'lodash.merge';

export const SET_DEVICE_STATE = 'SET_DEVICE_STATE';
export const REPLACE_DEVICE_STATE = 'REPLACE_DEVICE_STATE';

export const deviceReducer = (state, action) => {
    const { type, payload } = action;
    // console.log('deviceReducer', state, action);

    switch (type) {
        case SET_DEVICE_STATE:
            return merge(
                cloneDeep(state),
                payload
            );
        case REPLACE_DEVICE_STATE:
            return payload;

        default:
            return state;
    }
};
