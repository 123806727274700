import React from 'react';
import classnames from 'classnames';

import styles from './VolumeBar.scss';

export const VolumeBar = props => {
    const { value, barClassName, wrapperClassName, inProcess } = props;

    return (
        <div className={classnames(styles.wrapper, wrapperClassName)}>
            <div className={classnames(styles.barWrapper, { [styles.isVisible]: inProcess })}>
                <div
                    style={{ width: `${value}%` }}
                    className={classnames(styles.bar, barClassName, { [styles.inProcess]: inProcess })}
                />
            </div>
        </div>
    )
};