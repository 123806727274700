import React, { createContext, useReducer } from 'react';
import localStorage from '../helpers/localStorage';
import { clientReducer } from '../reducers/clientReducer';


const initialState = {
    accessToken: localStorage.getItem('accessToken'),
    wsHost: localStorage.getItem('wsHost'),
    isConnected: false,
    isConnecting: false,
    isAuthorized: false,
    error: null,
    wsReadyState: null,
};

export const ClientContext = createContext({});

export const ClientContextProvider = ({ children }) => {
    const [clientState, clientDispatch] = useReducer(clientReducer, initialState);

    return <ClientContext.Provider value={{ clientState, clientDispatch }}>{children}</ClientContext.Provider>;
};

