import { ReactNode } from 'react';
import { CSSTransition } from 'react-transition-group';

import stylesFade from './Fade.scss';
import stylesFadeSlow from './FadeSlow.scss';
import SlideDown40vh from './SlideDown40vh.scss';

type PropsType = {
    children: ReactNode;
    in: boolean;
    type?: string;
    onEnter?: () => void;
    onExited?: () => void;
};

type StylesType = Record<string, Record<string, string>>;

const styles: StylesType = {
    fade: stylesFade,
    fadeSlow: stylesFadeSlow,
    slideDown40vh: SlideDown40vh,
};

// const timeoutMap: Record<string, number> = {
//     slideDown: 2000,
// };

export const Animate = (props: PropsType) => {
    const { children, in: inProp, type = 'fade', onEnter = () => {}, onExited = () => {} } = props;
    const { timeout } = styles[type];

    if (typeof timeout === 'undefined') {
        throw Error(`Styles for '${type}' type does not have 'timeout' exported!`);
    }

    return (
        <CSSTransition
            in={inProp}
            timeout={parseInt(timeout)}
            classNames={styles[type]}
            unmountOnExit
            onEnter={onEnter}
            onExited={onExited}
        >
            {children}
        </CSSTransition>
    );
};
