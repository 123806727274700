import React, { useState } from 'react';
import get from 'lodash.get';
import { Icon } from '../Icon/Icon';
import { DebugEntry } from './DebugEntry';
import { DebugOneLineProperty } from './DebugOneLineProperty';
import { DeviceManager } from '../DeviceManager/DeviceManager';
import { stateName as stateNames } from '../../constants';

import styles from './DebugDevice.scss';
import { DebugObjectSize } from './DebugObjectSize';

export const DebugDevice = ({ uid, name, data, state, dataPath, stateName }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isManagerVisible, setIsManagerVisible] = useState(false);
    const friendlyName = get(data, 'info.friendlyName') || get(data, 'friendlyName');
    const isZigbeeDevice = get(data, 'info.deviceSubType', []).includes('zigbee');

    if (!data) {
        return null;
    }

    const showIsTruthyEl =
        stateName === stateNames.AUTOMATION_STATE &&
        data.isTruthy &&
        data.isTruthy.value !== 'undefined';

    const entryEl = (
        <ul className={styles.ul}>
            {isVisible && (
                <>
                    {!isManagerVisible && (
                        <button className="btn btn-xs" onClick={() => setIsManagerVisible(true)}>
                            Manage
                        </button>
                    )}
                    {isManagerVisible && (
                        <DeviceManager uid={uid} data={data} stateName={stateName} />
                    )}
                </>
            )}
            {isVisible &&
                Object.entries(data).map(([name, property]) => (
                    <DebugEntry
                        key={name}
                        uid={uid}
                        name={name}
                        property={property}
                        state={state}
                        dataPath={dataPath}
                    />
                ))}
        </ul>
    );

    return (
        <div>
            <h3 className={styles.uid} onClick={() => setIsVisible(!isVisible)}>
                <Icon
                    className={styles.iconUid}
                    name={isVisible ? 'mdi/minus' : 'mdi/plus'}
                    width={15}
                    height={15}
                />
                {friendlyName ? `${friendlyName} [${name}]` : name}
                {isZigbeeDevice && ' (zigbee)'}

                {<DebugObjectSize obj={data} />}

                {typeof data !== 'object' && (
                    <DebugOneLineProperty className={styles.valueInRoot} val={data} />
                )}

                {showIsTruthyEl && (
                    <span className={styles.isTruthy}>
                        {data.isTruthy.value ? '[TRUE]' : '[FALSE]'}
                    </span>
                )}
            </h3>
            {typeof data === 'object' && entryEl}
        </div>
    );
};
