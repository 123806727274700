import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import get from 'lodash.get';
import { stateName } from 'src/constants';
import { DebugStates } from 'src/components/DebugStates/DebugStates';

import styles from './debug.scss';

export const Debug = props => {
    return (
        <>
            <h1>Debug</h1>

            <ul className={styles.ul}>
                <li>
                    <NavLink to="/debug/states">states</NavLink>
                </li>
                <li>
                    <NavLink to="/debug/data-stream">Data stream</NavLink>
                </li>
                <li>
                    <NavLink to="/debug/chart/sims">Sims</NavLink>
                </li>
                <li>
                    <NavLink to="/debug/chart/esp30aea41848c4/4">Terrace</NavLink>
                </li>
                <li>
                    <NavLink to="/debug/chart/esp30aea4030f4c/15">Living Room</NavLink>
                </li>
                <li>
                    <NavLink to="/debug/chart/0x00158d00036b2f52/0">Living Room Heater</NavLink>
                </li>
                <li>
                    <NavLink to="/debug/chart/0x00158d0002f3ff2d/0">Hallway</NavLink>
                </li>
                {/*<li><NavLink to="/debug/chart/rpi_bedroom123/3">Bedroom</NavLink></li>*/}
                <li>
                    <NavLink to="/debug/chart/0x00158d0003cd2887/0">Bathroom</NavLink>
                </li>
                <li>
                    <NavLink to="/debug/expo-map">ExpoMap Generator</NavLink>
                </li>
            </ul>

            <div style={{ padding: '1rem 2rem' }}>
                <DebugStates stateName={stateName.PLAYER_STATE} />
                <DebugStates stateName={stateName.DEVICE_STATE} />
                <DebugStates stateName={stateName.TRIGGER_STATE} />
                <DebugStates stateName={stateName.AUTOMATION_STATE} />
            </div>
        </>
    );
};
