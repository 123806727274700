let gotFirstResponse = false;

export const onMessage = (dispatcher, dataStream, { data }) => {
    let obj;

    try {
        obj = JSON.parse(data);
    } catch (err) {
        return console.warn('failed to parse ws message:', data);
    }

    const { topic, payload } = obj;

    switch (topic) {
        case 'stateDiff':
        case 'stateReplace': {
            if (!gotFirstResponse) {
                gotFirstResponse = true;
                console.timeEnd('authenticate');
            }

            Object.entries(payload).forEach(([key, state]) => {
                if (typeof dispatcher[`${key}Dispatch`] === 'function') {
                    const action = topic === 'stateReplace' ? 'REPLACE' : 'SET';
                    dispatcher[`${key}Dispatch`]({
                        type: `${action}_${key.toUpperCase()}_STATE`,
                        payload: state,
                    });
                } else {
                    console.warn('state not found', { key, payload, dispatcher });
                }
            });

            return;
        }

        case 'error':
            console.warn(payload);
            return alert(payload.message);

        case 'dataStream':
            dataStream.stream(payload);
            break;

        default:
            console.warn('unhandled message', payload);
    }
};
