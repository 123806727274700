import React from 'react';
import { DebugStates } from './DebugStates';
import { stateName } from '../../constants';

export const DebugAllStates = () => {
    return (
        <div>
            <DebugStates stateName={stateName.DEVICE_STATE} title="Mirror" selector="esp30aea43c0264" />
            <DebugStates stateName={stateName.DEVICE_STATE}/>
            <DebugStates stateName={stateName.PLAYER_STATE}/>
            <DebugStates stateName={stateName.TRIGGER_STATE}/>
            <DebugStates stateName={stateName.AUTOMATION_STATE}/>
            <DebugStates stateName={stateName.CLIENT_STATE}/>
            <DebugStates stateName={stateName.LIBRARY_STATE}/>
            <DebugStates stateName={stateName.TORRENT_STATE}/>
        </div>
    );
};
