import { ReactNode } from 'react';

import styles from './NavBarExtraButtonWrapper.scss';

type PropsType = {
    children: ReactNode;
};

export const NavBarExtraButtonWrapper = (props: PropsType) => {
    const { children } = props;
    return <div className={styles.wrapper}>{children}</div>;
};
