import { Icon } from 'src/components/Icon/Icon';
import classnames from 'classnames';

import styles from './NavBarExtraButton.scss';

type PropsType = {
    iconName: string;
    isActive: boolean;
    title: string;
    onClick: (arg0: any) => void;
};

export const NavBarExtraButton = (props: PropsType) => {
    const { iconName, isActive, title, onClick } = props;

    const wrapperClassName = classnames(styles.wrapper, {
        [styles.isActive]: isActive,
    });

    return (
        <div className={wrapperClassName} onClick={onClick}>
            <Icon name={iconName} className={styles.icon} />
            <div className={styles.title}>{title}</div>
        </div>
    );
};
