import { SET_FOREGROUND_STATE } from '../reducers/uiReducer';
import { send } from '../hooks/useWebsocket/useWebsocket';

export const setForegroundState = state => {
    send({ topic: 'foregroundState', payload: { state } });
    return {
        type: SET_FOREGROUND_STATE,
        payload: state,
    };
};
