import cloneDeep from 'lodash.clonedeep';
import merge from 'lodash.merge';

export const SET_TORRENT_STATE = 'SET_TORRENT_STATE';

export const torrentReducer = (state, action) => {
    const { type, payload } = action;
    // console.log('torrentReducer', state, action);

    switch (type) {
        case SET_TORRENT_STATE:
            return merge(
                cloneDeep(state),
                payload
            );

        default:
            return state;
    }
};
