import React, { useRef, useContext } from 'react';
import ReactDOM from 'react-dom';
import { NavLink, useHistory } from 'react-router-dom';
import classnames from 'classnames';
import get from 'lodash.get';

import { Icon } from '../Icon/Icon';
import { NavBarExtra } from './NavBarExtra/NavBarExtra';
import { NavBarSwipeArea } from './NavBarSwipeArea/NavBarSwipeArea';
import { UiContext } from '../../context/uiContext';

import styles from './navBar.scss';

const defaultTabBarItems = [
    {
        id: 'home',
        title: 'Home',
        icon: 'icon54/home.8',
        link: '/',
    },
    {
        id: 'floorPlan',
        title: 'Plan',
        icon: 'untitled/floor-plan',
        link: '/floorPlan',
    },
    {
        id: 'camera',
        title: 'Camera',
        icon: 'mdi/cctv',
        link: '/camera',
    },
    {
        id: 'sonyBraviaRemote',
        title: 'Remote',
        icon: 'icon54/remote-control.3',
        link: '/sonyBravia',
    },
    {
        id: 'media',
        title: 'Media',
        icon: 'icon54/player.1',
        link: '/media',
    },
    {
        id: 'library',
        title: 'Library',
        icon: 'icon54/video-folder.1.1',
        link: '/library/browse',
    },
    {
        id: 'weatherForecast',
        title: 'Weather',
        icon: 'icon54/umbrella.3.1',
        link: '/weather',
    },
    {
        id: 'debug',
        title: 'Debug',
        icon: 'icon54/bug-fix',
        link: '/debug',
    },
];

export const NavBar = () => {
    const navEl = useRef(null);
    const history = useHistory();
    const {
        uiState: { tabBarItems = defaultTabBarItems, navBar: navBarState },
    } = useContext(UiContext);

    const onClick = e => {
        e.stopPropagation();
        const width = parseInt(getComputedStyle(navEl.current).width);
        const percent = (e.clientX / width) * 100;
        const index = Math.floor((tabBarItems.length * percent) / 100);
        const path = get(tabBarItems, `[${index}].link`);

        if (path) {
            history.push(path);
        }
    };

    // <div
    //     className={classnames(styles.wrapper, {
    //         [styles.wrapperExtended]: navBarState.isExpanded,
    //     })}
    // >

    const result = (
        <>
            <nav className={styles.navBar} ref={navEl}>
                {tabBarItems.map(({ id, title, icon, link }) => {
                    return (
                        <NavLink
                            key={id}
                            className={styles.navBarItem}
                            to={link}
                            exact={link === '/'}
                            activeClassName={styles.navBarItemActive}
                        >
                            <Icon name={icon} className={styles.icon} width={25} height={25} />
                            <span className={`navBarItemTitle ${styles.navBarItemTitle}`}>
                                {title}
                            </span>
                        </NavLink>
                    );
                })}
            </nav>

            <NavBarSwipeArea onClick={onClick} />
        </>
    );

    return ReactDOM.createPortal(result, document.getElementById('navbar'));
};
