import React, { lazy, useContext, useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import classnames from 'classnames';

// components
import { Login } from '../Login/Login';
import { NavBar } from '../NavBar/NavBar';

// route components
import { Home } from '../routes/Home/Home';
import { ColorPickerWindow } from '../ColorPicker/ColorPickerWindow';
import { Debug } from '../routes/Debug/Debug';
import { DebugAllStates } from '../DebugStates/DebugAllStates';

// other
import { UiContext } from '../../context/uiContext';
import { SET_ROUTER_STATE, SET_SETTINGS_VISIBILITY } from '../../reducers/uiReducer';

// hooks
import { useForegroundState } from '../../hooks/useForegroundState';

import { setForegroundState } from '../../actions/uiActions';
import { getAppInfo, hasNewAppVersion } from 'src/helpers/updates';
import { Settings } from '../Settings/Settings';

import styles from './layout.scss';
import { ClientContext } from 'src/context/clientContext';

const FloorPlan = lazy(() => import('../routes/FloorPlan/FloorPlan'));
const Camera = lazy(() => import('../routes/Camera/Camera'));
const DataStream = lazy(() => import('../routes/Debug/DataStream/DataStream'));
const Chart = lazy(() => import('../routes/Chart/Chart'));
const ChartUid = lazy(() => import('../routes/Chart/ChartUid'));
const Media = lazy(() => import('../routes/Media/MediaPlayer/MediaPlayer'));
const Library = lazy(() => import('../routes/Library/Library'));
const WeatherMap = lazy(() => import('../WeatherForecast/WeatherMap/WeatherMap'));
const ExpoMap = lazy(() => import('../routes/Debug/ExpoMap/ExpoMap'));

export const Layout = () => {
    const {
        uiState: { settingsIsVisible },
        uiDispatch,
    } = useContext(UiContext);
    const { clientState } = useContext(ClientContext);
    const location = useLocation();
    const foregroundState = useForegroundState();
    const [hasUpdate, setHasUpdate] = useState(false);

    useEffect(() => {
        uiDispatch({
            type: SET_ROUTER_STATE,
            payload: location,
        });
    }, [location]);

    useEffect(() => {
        uiDispatch(setForegroundState(foregroundState));
    }, [foregroundState]);

    useEffect(() => {
        setTimeout(async () => {
            if (await hasNewAppVersion()) {
                uiDispatch({
                    type: SET_SETTINGS_VISIBILITY,
                    payload: true,
                });
            }

            const { appVersion } = await getAppInfo();
            console.info(
                `Current version: ${process.env.appVersion}, latest version: ${appVersion}`
            );
        }, 2000);
    }, []);

    useEffect(() => {
        // TODO: share this in uiDispatch
        const className = 'body-no-scroll';
        settingsIsVisible
            ? document.body.classList.add(className)
            : document.body.classList.remove(className);
    }, [settingsIsVisible]);

    if (!clientState.isAuthorized) {
        return <Login />;
    }

    return (
        <div className={styles.bodyWrapper}>
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/floorPlan" component={FloorPlan} />
                <Route path="/camera" component={Camera} />
                {/*<Route path="/sonyBravia" component={SonyBraviaRemote}/>*/}
                <Route path="/media/:uid?" component={Media} />
                <Route path="/library" component={Library} />
                <Route path="/weather/:mapType?" component={WeatherMap} />

                <Route path="/debug" exact component={Debug} />
                <Route path="/debug/data-stream" component={DataStream} />
                <Route path="/chart/sims" component={Chart} />
                <Route path="/chart/:uid/:address" component={ChartUid} />
                <Route path="/debug/states" exact component={DebugAllStates} />
                <Route path="/debug/expo-map" exact component={ExpoMap} />
            </Switch>

            <ColorPickerWindow />
            <NavBar />

            <Settings />

            {foregroundState !== 'active' && <div className={styles.backgroundMode} />}
        </div>
    );
};
