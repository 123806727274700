import { useEffect, useRef } from 'react';

export const useResize = ({ onResize = () => {} } = {}) => {
    const observer = useRef(null);
    const ref = useRef(null);

    useEffect(() => {
        const node = ref?.current;

        if (node !== null) {
            if (observer.current) {
                observer.current.disconnect();
            }

            observer.current = new ResizeObserver(onResize);

            const { current: currentObserver } = observer;

            currentObserver.observe(node);

            return () => currentObserver.disconnect();
        }
    }, [ref]);

    return [ref];
};
