export const hashCode = (str, obj) => {
    if (!str) {
        console.log(obj);
    }
    let hash = 0;
    let i;
    let chr;
    if (str === 0) {
        return hash;
    } else if (typeof str === 'object') {
        str = JSON.stringify(str);
    }
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};

export const capitalizeFirstLetter = str => {
    if (!str.length) {
        return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const mapRange = (num, inMin, inMax, outMin, outMax) => {
    return ((num - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
};

export const hr2timestamp = hrtime => {
    const nanoseconds = hrtime[0] * 1e9 + hrtime[1];
    const milliseconds = nanoseconds / 1e6;
    const seconds = nanoseconds / 1e9;

    return {
        seconds,
        milliseconds,
        nanoseconds,
    };
};
