import React, { FC, Touch, useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Animate } from '../Animate/Animate';

import styles from './ActionMenu.scss';

type PropsType = {
    children: React.ReactNode;
    showBackdrop?: boolean;
    onClose?: () => void;
    visible: boolean;
    timeout?: number;
};

export const ActionMenu: FC<PropsType> = (props: PropsType) => {
    const { children, visible, showBackdrop = true, onClose = () => {} } = props;
    const refHolder = useRef(null);
    const refVisible = useRef(null);
    const [pushDown, setPushDown] = useState(0);
    // const [scrollTop, setScrollTop] = useState(0);
    let isScrollTop = false;
    let touchStart: Touch;
    let lastMove: Touch;

    useEffect(() => {
        if (!visible && pushDown > 0) {
            setPushDown(0);
        }
    }, [visible]);

    const onTouchStart = useCallback(
        ev => {
            touchStart = ev.touches[0];
            if (refHolder.current !== null) {
                console.log('onTouchStart', (refHolder.current! as HTMLElement).scrollTop);
                isScrollTop = (refHolder.current! as HTMLElement).scrollTop === 0;
            }
        },
        [refHolder]
    );

    const onTouchMove = useCallback(
        ev => {
            lastMove = ev.touches[0];
            const moveDiff = lastMove.clientY - touchStart.clientY;
            if (isScrollTop && moveDiff > 0) {
                setPushDown(moveDiff * 0.3);

                if (moveDiff > 120) {
                    onClose();
                }
            }
        },
        [refHolder, isScrollTop]
    );

    const onExited = useCallback(() => {
        setPushDown(0);
    }, [setPushDown]);

    // const onTouchEnd = useCallback(() => {
    //     // if (refHolder.current !== null && lastMove && touchStart) {
    //     //     const swipeXDistance = lastMove.clientX - touchStart.clientX;
    //     //     const swipeYDistance = lastMove.clientY - touchStart.clientY;
    //     //     if (isScrollTop && Math.abs(swipeXDistance) < 40 && swipeYDistance > 120) {
    //     //         console.log(`swipeYDistance: ${swipeYDistance}. Closing...`);
    //     //         onClose();
    //     //     } else {
    //     //         console.log(`swipeYDistance: ${swipeYDistance}. NOT closing...`);
    //     //         setPushDown(0);
    //     //     }
    //     // }
    // }, [refHolder, isScrollTop, onClose]);

    const result = (
        <>
            {showBackdrop && (
                <Animate in={visible} type="fade">
                    <div className="backdrop" onClick={onClose} />
                </Animate>
            )}

            <Animate in={visible} type="slideDown40vh" onExited={onExited}>
                <div
                    className={styles.holder}
                    onTouchStart={onTouchStart}
                    onTouchMove={onTouchMove}
                    // onTouchEnd={onTouchEnd}
                    // onMouseLeave={onTouchEnd}
                    ref={refHolder}
                >
                    <div
                        className={styles.topSpace}
                        onClick={() => {
                            onClose && onClose();
                        }}
                    ></div>
                    <div className={styles.wrapper} onClick={onClose}>
                        <div
                            className={styles.visibleWrapper}
                            style={{ transform: `translateY(${pushDown}px)` }}
                            ref={refVisible}
                            onClick={ev => ev.stopPropagation()}
                        >
                            {children}
                        </div>
                    </div>
                </div>
            </Animate>
        </>
    );

    return ReactDOM.createPortal(result, document.getElementById('actionMenu')!);
};
