import { useEffect, useContext } from 'react';
import { send } from '../useWebsocket/useWebsocket';
import { ClientContext } from '../../context/clientContext';

const PUBLIC_KEY =
    'BAVmOoNqmfk-ooO0Cu5QKQ2EPk6y-yngyxP_l718bg5CkhpKyVjKzubT3GBkv_j_fxXNNhrFjlNzrr31i3WOeKQ';
const PERMISSION = {
    default: 'default',
    granted: 'granted',
    denied: 'denied',
};

const postMessageToWorker = ({ topic, payload }) => {
    // USAGE:
    // const respFromWorker = await postMessageToWorker({ topic, payload });

    return new Promise((resolve, reject) => {
        const messageChannel = new MessageChannel();
        messageChannel.port1.onmessage = e => {
            if (e.data.error) {
                reject(e.data.error);
            } else {
                resolve(e.data);
            }
        };

        // This sends the message data as well as transferring messageChannel.port2 to the service worker.
        // The service worker can then use the transferred port to reply via postMessage(), which will in
        // turn trigger the onmessage handler on messageChannel.port1.
        // See https://html.spec.whatwg.org/multipage/workers.html#dom-worker-postmessage
        navigator.serviceWorker.controller.postMessage(JSON.stringify({ topic, payload }), [
            messageChannel.port2,
        ]);
    });
};

const onPostMessage = e => {
    const { data } = e;
    try {
        const { topic, payload } = typeof data === 'string' ? JSON.parse(data) : data;
        console.log('onPostMessage', { topic, payload });
    } catch (err) {
        console.error('Failed to parse postMessage', data, err);
    }
};

const urlB64ToUint8Array = base64String => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('message', onPostMessage.bind(this));
}

export const useServiceWorker =
    'serviceWorker' in navigator
        ? () => {
              // will be used to dispatch messages in foreground

              const { clientState } = useContext(ClientContext);
              const { isAuthorized } = clientState;

              useEffect(() => {
                  if (isAuthorized) {
                      navigator.serviceWorker.ready.then(async container => {
                          if (!container.pushManager) {
                              console.log('pushManager not supported');
                              return;
                          }

                          if (Notification.permission === PERMISSION.denied) {
                              console.error('Notification permission is DENIED');
                              return;
                          }

                          const currentSubscription = await container.pushManager.getSubscription();
                          // console.log({ currentSubscription });

                          const additionalSubscriptionData = {
                              features: Object.keys(Notification.prototype),
                              userAgent: navigator.userAgent,
                          };

                          if (currentSubscription) {
                              send({
                                  topic: 'pushSubscriptionData',
                                  payload: {
                                      data: currentSubscription.toJSON(),
                                      ...additionalSubscriptionData,
                                  },
                              });
                              return;
                          }

                          if (
                              Notification.permission === PERMISSION.default ||
                              (Notification.permission === PERMISSION.granted &&
                                  !currentSubscription)
                          ) {
                              const permission = await Notification.requestPermission();
                              if (permission === PERMISSION.granted) {
                                  const applicationServerKey = urlB64ToUint8Array(PUBLIC_KEY);
                                  const options = { applicationServerKey, userVisibleOnly: true };
                                  const subData = await container.pushManager.subscribe(options);

                                  if (subData) {
                                      send({
                                          topic: 'pushSubscriptionData',
                                          payload: {
                                              data: subData.toJSON(),
                                              ...additionalSubscriptionData,
                                          },
                                      });
                                  }
                              } else {
                                  console.error(`WebPush permission: ${permission}`);
                              }
                          }
                      });
                  }
              }, [isAuthorized]);
          }
        : () => {};
