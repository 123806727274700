import React from 'react';
import get from 'lodash.get';
import dayjs from 'dayjs';
import TimeAgo from 'react-timeago';
import { emitAction } from '../../actions/actions';

import styles from './DebugOneLineProperty.scss';


const methods = {
    value({ val, dataPath, toggleRelay }) {
        const pathSegments = dataPath.split('.'); // esp30aea43c0264.relay.12.value
        let relayCta = null;

        if (pathSegments[pathSegments.length - 3] === 'relay') {
            const address = pathSegments[pathSegments.length - 2];
            relayCta =
                <button
                    className={styles.cta}
                    onClick={() => toggleRelay({ address })}
                >Toggle</button>;
        }

        if (typeof val === 'boolean') {
            return <>{val ? 'True' : 'False'} {relayCta}</>
        }
        return <>{val}</>;
    },
    changeTime({ val }) {
        if (typeof val === 'number') {
            const monthBefore = dayjs().subtract(1, 'month').unix();
            const monthAfter = dayjs().add(1, 'month').unix();
            const valInSeconds = Math.round(val / 1000);
            if (valInSeconds > monthBefore && valInSeconds < monthAfter) {
                return <TimeAgo date={val} />;
            } else {
                return <>{dayjs(val).format('YYYY-MM-DD HH:mm:ss')}</>;
            }
        }
        return <>{val} no number: {typeof val}</>;
    },
    humidity({ val }) {
        return <>{val}%</>
    },
    temperature({ val }) {
        return <>{val}&deg;C</>
    },
    pressure({ val }) {
        return <>{val} hPa</>
    },
};

export const DebugOneLineProperty = ({ className, uid, name, val, state, dataPath }) => {
    const type = typeof val;
    let el;

    const toggleRelay = ({ address }) => {
        emitAction({ uid, controller: 'relay', address, action: 'toggle' });
    };

    switch (true) {
        case typeof methods[name] === 'function':
            el = methods[name]({ uid, val, state, dataPath, toggleRelay });
            break;

        case type === 'string':
            el = <>"{val}"</>;
            break;

        case type === 'number':
            el = <>{val}</>;
            break;

        case type === 'boolean':
            el = <>{val ? 'True' : 'False'}</>;
            break;

        default:
            el = <>"{val}"</>;
    }

    return (
        <span className={className}>
            {el}
        </span>
    );
};
