import { send } from '../hooks/useWebsocket/useWebsocket';

// uid: 'esp30aea4030f4c',
// controller: 'relay',
// address: 18,
// action: 'toggle',
// icon: 'sun',
// title: 'Floor'

export const emitAction = ({ uid, controller, address, action, value, data }) => {
    if (data) {
        console.error(`emitAction: data in payload is deprecated`, { uid, controller, address, action, data });
    }
    const topic = `device.action`;
    send({
        topic,
        payload: { uid, controller, address, action, value, data },
    });
};

export const torrentAction = ({ action, payload }) => {
    send({ topic: `torrent.${action}`, payload });
};

export const automationAction = ({ action, payload }) => {
    send({ topic: `automation.${action}`, payload });
};

export const emitRaw = ({ topic, payload }) => {
    if (!topic || !payload) {
        return console.error('Bad data supplied', topic, payload);
    }
    send({ topic, payload });
};
