import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DeviceContext } from 'src/context/deviceContext';
import { UiContext } from 'src/context/uiContext';
import { getAppInfo, hasNewAppVersion } from 'src/helpers/updates';
import { SET_SETTINGS_VISIBILITY } from 'src/reducers/uiReducer';
import { ActionMenu } from '../ActionMenu/ActionMenu';
import { ActionMenuHeader } from '../ActionMenu/ActionMenuHeader/ActionMenuHeader';
import { ActionMenuList } from '../ActionMenu/ActionMenuList/ActionMenuList';
import { ActionMenuListItem } from '../ActionMenu/ActionMenuListItem/ActionMenuListItem';
import { DebugDevice } from '../DebugStates/DebugDevice';

import styles from './Settings.scss';

export const Settings = () => {
    const {
        uiState: { settingsIsVisible },
        uiDispatch,
    } = useContext(UiContext);
    const [latestAppVersion, setLatestAppVersion] = useState('');
    const [hasUpdate, setHasUpdate] = useState(false);
    // @ts-ignore
    const { deviceState } = useContext(DeviceContext);

    const refreshApp = useCallback(async () => {
        if (typeof caches === 'undefined') {
            return window.location.reload();
        }
        const cacheNames = await caches.keys();
        const cacheDeleteResult = await Promise.all(
            cacheNames.map(cacheName => caches.delete(cacheName))
        );
        console.log('clearing caches', cacheDeleteResult);

        const registrations = await navigator.serviceWorker.getRegistrations();
        const unregisterResult = await Promise.all(
            registrations.map(registration => registration.unregister())
        );

        console.log('unregistering serviceWorkers', unregisterResult);
        window.location.reload();
    }, []);

    const closeSettings = useCallback(() => {
        console.log('close settings');
        uiDispatch({
            type: SET_SETTINGS_VISIBILITY,
            payload: false,
        });
    }, [uiDispatch]);

    const checkForNewVersion = useCallback(async force => {
        const hasNewVersion = await hasNewAppVersion(force);
        setHasUpdate(hasNewVersion);
        const { appVersion } = await getAppInfo();
        setLatestAppVersion(appVersion);
    }, []);

    useEffect(() => {
        checkForNewVersion(false);
    }, []);

    // const arr = Array.from(Array(100).keys());

    const uid = 'esp30aea4030f4c';

    return (
        <ActionMenu onClose={closeSettings} visible={settingsIsVisible}>
            <ActionMenuHeader
                title="Settings"
                iconName="icon54/settings.1"
                onClose={closeSettings}
            />

            <div className={styles.content}>
                <p>
                    App version: {process.env.appVersion}
                    {hasUpdate && ` (Latest ${latestAppVersion})`}
                    {!hasUpdate && ` (Up to date)`}
                </p>
                {hasUpdate && process.env.NODE_ENV === 'development' && (
                    <div>
                        <small>DevMode detected: please restart process!</small>
                    </div>
                )}
            </div>

            <ActionMenuList>
                <ActionMenuListItem
                    icon="icon54/information.2"
                    onClick={() => checkForNewVersion(true)}
                >
                    Check for updates
                </ActionMenuListItem>
                <ActionMenuListItem icon="mdi/reload" onClick={refreshApp}>
                    <span>{hasUpdate ? 'Update' : 'Reload'} app</span>
                </ActionMenuListItem>

                {/* {arr.map(k => {
                    return (
                        <ActionMenuListItem key={k} icon="icon54/information.2" onClick={() => {}}>
                            {k}
                        </ActionMenuListItem>
                    );
                })} */}
            </ActionMenuList>

            <DebugDevice
                name={uid}
                uid={uid}
                data={deviceState[uid]}
                state={{ [uid]: deviceState[uid] }}
                dataPath={uid}
                stateName={'deviceState'}
            />
        </ActionMenu>
    );
};
