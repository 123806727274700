import get from 'lodash.get';
import { controllerState, controllerWithAddress, flatStateController } from '../constants';

export const getChannelFromState = ({ state, uid, controller }) => {
    if (!uid) {
        return console.error(`getChannelFromState: uid not defined`);
    }

    return get(state, `['${uid}'].info.channel`) || get(state, `['${uid}'].channel`);
};

export const getValueFromState = ({ state, uid, controller, address, selector }) => {
    const segments = [uid, ...(controller ? [controller] : []), address, selector].filter(
        item => typeof item !== 'undefined'
    );
    return get(state, `${segments.join('.')}`);
};

export const getIsActiveFromState = props => {
    console.warn('getIsACtiveFromState is deprecated');
    const { uid, controller, address, selector } = props;

    if (!uid || !controller) {
        return console.error(`getIsActiveFromState: not enough data supplied`);
    }

    const stateName = controllerState[controller];

    if (!stateName) {
        return console.error(
            `getIsActiveFromState: stateName not found for controller "${controller}"`,
            controllerState,
            controller
        );
    }

    const hasAddress = controllerWithAddress.includes(controller);

    if (hasAddress && typeof address === 'undefined') {
        return console.error(`getIsActiveFromState: controller "${controller}" needs address`);
    }

    const isFlatStateController = flatStateController.includes(controller);

    // flatStateControllers does not nest data in 'controller' property
    const segments = [
        stateName,
        uid,
        !isFlatStateController && controller,
        'data',
        address,
        selector,
    ].filter(item => typeof item !== 'undefined');

    return get(props, `${segments.join('.')}`) === true;
};
