import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

// components
import { Layout } from './Layout/Layout';

// context providers
import { ClientContextProvider } from '../context/clientContext';
import { DeviceContextProvider } from '../context/deviceContext';
import { TriggerContextProvider } from '../context/triggerContext';
import { LibraryContextProvider } from '../context/libraryContext';
import { PlayerContextProvider } from '../context/playerContext';
import { TorrentContextProvider } from '../context/torrentContext';
import { AutomationContextProvider } from '../context/automationContext';
import { UiContextProvider } from '../context/uiContext';

import { useWebsocket } from '../hooks/useWebsocket/useWebsocket';
import { useServiceWorker } from '../hooks/useServiceWorker/useServiceWorker';
import { ThemeSelector } from './ThemeSelector/ThemeSelector';

const Websocket = () => {
    useWebsocket();
    return null;
};

const ServiceWorker = () => {
    useServiceWorker();
    return null;
};

export default () => (
    <UiContextProvider>
        <AutomationContextProvider>
            <ClientContextProvider>
                <DeviceContextProvider>
                    <TriggerContextProvider>
                        <LibraryContextProvider>
                            <PlayerContextProvider>
                                <TorrentContextProvider>
                                    <>
                                        <Websocket />
                                        <ServiceWorker />
                                        <Router>
                                            <Suspense fallback={<div>Loading...</div>}>
                                                <ThemeSelector>
                                                    <Layout />
                                                </ThemeSelector>
                                            </Suspense>
                                        </Router>
                                    </>
                                </TorrentContextProvider>
                            </PlayerContextProvider>
                        </LibraryContextProvider>
                    </TriggerContextProvider>
                </DeviceContextProvider>
            </ClientContextProvider>
        </AutomationContextProvider>
    </UiContextProvider>
);
