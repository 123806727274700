import React, { useState, useRef, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import { useDebounce } from 'use-debounce';
import { rgbToHsv } from './helpers';

import styles from './ColorPicker.scss';

let canvas;
let ctx;
let handleData = {};
let pos = { x: 0, y: 0 };
let sentColorValue;

const renderColorMap = () => {
    const img = new Image();
    // img.src = "/colorWheel.png";
    img.src = "/colorWheelBlack.png";
    img.src = "/colorWheelTransparend.png";
    img.onload = function() {
        console.log('canvas image loaded');
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    }
};

const renderColorMap____dynamic = () => {
    const radius = canvas.width / 2;
    const toRad = (2 * Math.PI) / 360;
    const step = 1 / radius;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const cx = radius;
    const cy = radius;
    for (let i = 0; i < 360; i += step) {
        const rad = i * toRad;
        const x = radius * Math.cos(rad);
        const y = radius * Math.sin(rad);

        ctx.strokeStyle = 'hsl(' + i + ', 100%, 50%)';

        ctx.beginPath();
        ctx.moveTo(radius, radius);
        ctx.lineTo(cx + x, cy + y);
        ctx.stroke();
    }

    // draw saturation gradient
    const grd = ctx.createRadialGradient(cx, cy, 0, cx, cx, radius);
    grd.addColorStop(0, 'white');
    grd.addColorStop(1, 'rgba(255, 255, 255, 0)');
    ctx.fillStyle = grd;
    //ctx.fillStyle = 'rgb(255, 255, 255)';
    ctx.beginPath();
    ctx.arc(cx, cy, radius, 0, Math.PI * 2, true);
    ctx.closePath();
    ctx.fill();
};

const getRgbPos = (r, g, b) => {
    const [h, s] = rgbToHsv(r, g, b);
    const theta = h * 2 * Math.PI;
    const maxRadius = canvas.width / 2;
    const ra = s * maxRadius;
    const x = ra * Math.cos(theta) + maxRadius;
    const y = ra * Math.sin(theta) + maxRadius;
    return { x, y };
};

const getDistanceFromCenter = ({ x, y }) => {
    const a = canvas.width / 2 - x;
    const b = canvas.height / 2 - y;
    return Math.sqrt(a * a + b * b);
};

const getAngleFromPos = ({ pos }) => {
    const c = { x: canvas.width / 2, y: canvas.height / 2 };
    const m = { x: pos.x, y: pos.y };
    const dx = m.x - c.x;
    const dy = m.y - c.y;
    const x = dx + c.x;
    const y = dy + c.y;
    const radians = Math.atan2(y - c.y, x - c.x);
    let angle = radians * (180 / Math.PI) + 90;
    return angle < 0 ? angle + 360 : angle;
};

const getPosFromAngle = ({ angle, subtract }) => {
    // subtract = subtract || canvas.width * 0.1;
    const radians = (angle / 180) * Math.PI;
    const radius = canvas.width / 2 - subtract;
    const center = {
        x: canvas.width / 2,
        y: canvas.height / 2,
    };

    return {
        x: radius * Math.sin(radians) + center.x, // x
        y: -radius * Math.cos(radians) + center.y, // y
    };
};

const applyLimits = ({ x, y }) => {
    const distanceFromCenter = getDistanceFromCenter({ x, y });
    const maxRadius = canvas.width / 2;
    if (distanceFromCenter > maxRadius) {
        const angle = getAngleFromPos({ pos: { x, y } });
        return getPosFromAngle({ angle, subtract: 1 });
    }
    return { x, y };
};

export const ColorPicker = props => {
    const { initialColor = [255, 255, 255], className, debounce = 500, maxWait = 300, onChange } = props;
    const canvasRef = useRef(null);
    const wrapperRef = useRef(null);
    const handleRef = useRef({ current: {} });
    const [handlePos, setHandlePos] = useState({ x: 0, y: 0 });
    const [isInitialized, setIsInitialized] = useState(false);
    const [color, setColor] = useState(initialColor);
    const [debouncedColor] = useDebounce(color, debounce, { maxWait });
    const wrapperClassNames = classnames(styles.colorWheel, className);

    const getColor = ({ x, y }) => {
        const imgData = ctx.getImageData(x, y, 1, 1).data;
        setColor(imgData);
    };

    const setPos = useCallback(
        ({ x, y }) => {
            Object.assign(pos, applyLimits({ x, y }));
            getColor(pos);

            setHandlePos({
                x: pos.x - handleData.width / 2,
                y: pos.y - handleData.height / 2,
            });

            if (typeof onChange === 'function' && sentColorValue !== debouncedColor) {
                onChange({
                    rgb: debouncedColor,
                });
                sentColorValue = debouncedColor;
            }
        },
        [onChange, debouncedColor]
    );

    useEffect(() => {
        if (!isInitialized) {
            const wrapperRefComputedStyle = getComputedStyle(wrapperRef.current);
            const width = parseInt(wrapperRefComputedStyle.width);

            canvas = canvasRef.current;
            ctx = canvas.getContext('2d');
            canvas.height = canvas.width = width;

            const handleComputedStyles = getComputedStyle(handleRef.current);
            handleData = {
                width: parseInt(handleComputedStyles.width),
                height: parseInt(handleComputedStyles.height),
            };

            renderColorMap();

            // const rgb = [255, 0, 0];
            // setPos(getRgbPos(...rgb));
            setPos(getRgbPos(...initialColor));

            setIsInitialized(true);
        }
    }, [canvasRef, wrapperRef, handleRef, setPos, initialColor, isInitialized]);

    const onTouchMove = e => {
        const { touches } = e;
        const { target, clientX, clientY } = touches[0];
        const elInfo = target.getBoundingClientRect();

        setPos({
            x: clientX - elInfo.left,
            y: clientY - elInfo.top,
        });
    };

    const onTouchStart = onTouchMove;

    const eventHandlers = { onTouchStart, onTouchMove /*onTouchEnd*/ };

    const canvasEl = <canvas className={styles.canvas} ref={canvasRef} {...eventHandlers} />;

    const handleEl = (
        <div
            className={styles.handle}
            ref={handleRef}
            style={{ transform: `translate(${handlePos.x}px, ${handlePos.y}px)` }}
        />
    );

    return (
        <div className={wrapperClassNames} ref={wrapperRef}>
            <div className={styles.colorWheelContent}>
                {canvasEl}
                {handleEl}
            </div>
        </div>
    );
};
